import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from "react-redux";
import { FOUNDATION_PATH, FOUNDATION_CA_PATH, FAVORITES_PATH } from 'utils/constants';

const GRANT_SECRETARY_PATH = '/main/grant-secretary';

const useRestoreScrollYPosition = () => {

  const { pathname } = useLocation();

  const {
    fetchStatus: { funders: GSFunders, fundersCustomDocument: GSFundersCustomDocument },
  } = useSelector(state => state.grantSecretaryState);

  const {
    fetchStatus: {
      favoritesPagedList,
      favoritesPagedSharedList,
      favoriteFundersPagedList,
    }
  } = useSelector(state => state.favoritesState);

  const lastLocation = JSON.parse(localStorage.getItem('lastScrollLocation'));
  const isFoundationPage = (pathname.includes(FOUNDATION_PATH) || pathname.includes(FOUNDATION_CA_PATH));

  useEffect(() => {
    if (lastLocation && lastLocation.pathname !== pathname && !isFoundationPage) {

      window.scrollTo(0, 0);
      localStorage.removeItem('lastScrollLocation');
    }

    if (lastLocation && lastLocation.pathname === pathname) {

      window.scrollTo(0, 0);

      if (pathname === GRANT_SECRETARY_PATH && GSFunders && GSFundersCustomDocument) {
        window.scrollTo(0, lastLocation.scrollPosition);

      } else if (pathname === FAVORITES_PATH && favoriteFundersPagedList && favoritesPagedSharedList && favoritesPagedList) {
        window.scrollTo(0, lastLocation.scrollPosition);
      }
    }
  }, [pathname, GSFunders, GSFundersCustomDocument, favoriteFundersPagedList, favoritesPagedSharedList, favoritesPagedList, lastLocation]);


};

const storeLocationScrollPosition = () => {
  window.localStorage.setItem('lastScrollLocation', JSON.stringify({ pathname: window.location.pathname, scrollPosition: window.scrollY }));

};


export { useRestoreScrollYPosition, storeLocationScrollPosition };