import { createSlice } from "@reduxjs/toolkit";
import { deadlineApi } from "Services/DeadlineService";

export const initialState = {
    loadingList: false,
    importDialogPopup: {
        open: false,
        fileToImport: null,
    },
    openUpdateDialog: false,
    pagedList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'foundationName',
        orderDirection: 'asc',
        items: []
    },
    deadlineValues: {
        id: "",
        foundationName: {
            value: "",
            error: ""
        },
        category: {
            value: "",
            error: ""
        },
        ein: {
            value: null,
            error: ""
        },
        country: {
            value: null,
            error: ""
        },
        url: {
            value: false,
            error: ""
        },
        grantAmount: {
            value: null,
            error: ""
        },
        deadlineDate: {
            value: null,
            error: ""
        },
        upcoming: {
            value: null,
            error: ""
        },
        description: {
            value: null,
            error: ""
        },
    }
};

const manageDeadlineSlice = createSlice({
    name: "manage-deadlines",
    initialState,
    reducers: {
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state.deadlineValues[field].error = errors[0];
            });
        },
        handleSetImportDialogPopup: (state, action) => {
            state.importDialogPopup.open = action.payload.fileToImport;
            state.importDialogPopup.fileToImport = action.payload.fileToImport
        },
        handleOpenForm: (state, action) => {
            if(action.payload === false) {
                state.deadlineValues = initialState.deadlineValues;
            } 
            state.openUpdateDialog = action.payload;
        },
        handleUpdateDeadlineValues: (state, action) => {
            const { key, value } = action.payload;
            state.deadlineValues[key].value = value;
            state.deadlineValues[key].error = "";
        },
        handleSetDeadlineValues: (state, action) => {
            const { 
                id, 
                foundationName, 
                category, 
                ein, 
                country, 
                url, 
                grantAmount, 
                deadlineDate, 
                upcoming, 
                description,
            } = action.payload;

            state.deadlineValues = {
                id,
                foundationName: {
                    value: foundationName,
                    error: ""
                },
                category: {
                    value: category,
                    error: ""
                },
                ein: {
                    value: ein,
                    error: ""
                },
                country: {
                    value: country,
                    error: ""
                },
                url: {
                    value: url,
                    error: ""
                },
                grantAmount: {
                    value: grantAmount,
                    error: ""
                },
                deadlineDate: {
                    value: deadlineDate,
                    error: ""
                },
                upcoming: {
                    value: upcoming,
                    error: ""
                },
                description: {
                    value: description,
                    error: ""
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            deadlineApi.endpoints.getDeadlinePagedList.matchFulfilled, (state, action) => {
                state.loadingList = false;
                state.pagedList.pageNumber = action.payload.pageNumber;
                state.pagedList.pageSize = action.payload.pageSize;
                state.pagedList.pageCount = action.payload.pageCount;
                state.pagedList.totalCount = action.payload.totalCount;
                state.pagedList.orderField = action.payload.orderField;
                state.pagedList.orderDirection = action.payload.orderDirection;
                state.pagedList.items = action.payload.items;
            }
        )
        .addMatcher(
            deadlineApi.endpoints.getDeadlinePagedList.matchPending, (state, action) => {
                state.loadingList = true;
            }
        )
        .addMatcher(
            deadlineApi.endpoints.getDeadlinePagedList.matchRejected, (state, action) => {
                state.loadingList = false;
            }
        )
    }
});

export const {
    handleModelErrors,
    handleSetImportDialogPopup,
    handleOpenForm,
    handleSetDeadlineValues,
    handleUpdateDeadlineValues,
} = manageDeadlineSlice.actions;

export default manageDeadlineSlice.reducer;