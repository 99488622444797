import { useEffect, useState } from "react";

import DialogConfirmWrapper from "components/DialogConfirmWrapper";
import config from 'config';
import Cookies from "universal-cookie";
import { TungstenOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";

const WelcomePopup = ({ onCloseWelcome }) => {

    const [ openPopup, setOpenPopup ] = useState(false);

    const cookies = new Cookies();

    const setCookieVal = (key, value) => {
        cookies.set(key, value, { maxAge: 1000000, path: '/', sameSite: true });
    }

    const handleClose = () => {
        setCookieVal(config.welcomeMessage.keyVersion, 'true');
        setOpenPopup(false);

        onCloseWelcome();
    }

    useEffect(() => {
        const needOpen = cookies.get(config.welcomeMessage.keyVersion) !== 'true';
        setOpenPopup(needOpen);
    }, []);

    return (
        <DialogConfirmWrapper 
            openPopup={openPopup}
            onClose={handleClose}
            titleMessage="Welcome!"
            popupMessage={
                <>
                    {config.welcomeMessage.message.map((msg, index) => (
                        <Typography key={index} variant="body1" dangerouslySetInnerHTML={{ __html: msg }} />
                    ))}
                </>
            }
            confirmLabel="What's New?"
            isLoading={false}
            cancelButtonVisible={false}
            onConfirm={handleClose}
            startIcon={<TungstenOutlined />}
            size="sm"
        />
    )

}

export default WelcomePopup;