export const TRANSLATIONS_FR_CA = {
  labels: {
    user_name: "Nom d'utilisateur",
    password: 'Mot de passe',
    reset_password: 'Nueva contraseña',
    reset_password_confirm: 'Confirmez le mot de passe',
    login: 'Connexion',
    sign_up: "S'inscrire",
    forgot_password: "Mot de passe oublié?",
    go_back: "Retourner",
    organization: "Organisation",
    phone: "Téléphone",
    first_name: "Prénom",
    last_name: "Nom de famille",
    address: "Adresse",
    select: "Sélectionner",
    country: "Pays",
    complementary_address: "Adresse complémentaire",
    postal_code: "code postal",
    city: "Ville",
    state: "État",
    stateOrProvince: "État/Province",
    billing: "Facturation",
    use_same_address: "Utiliser la même adresse",
    loading: "Chargement...",
    register: "S'inscrire",
    message: "Un message",
    error: "Erreur",
    welcome_back: "Salut, bienvenue à nouveau",
    enter_credentials: "Entrez vos identifiants pour continuer",
    dont_have_account: "Vous n'avez pas de compte?",
    remember_me: "Souviens-toi de moi",
    sign_in: "S'identifier",
    already_have_account: "Vous avez déjà un compte?",
    subscription_plan: "Formule d'abonnement",
    country_billing: "facturation par pays",
    password_recovery: "Récupération de mot de passe",
    password_reset: "Réinitialisation du mot de passe",
    setting_up_account: "Configuration du compte",
    current_password: "mot de passe actuel",
    new_password: "Nouveau mot de passe",
    confirm_password: "Confirmez le mot de passe",
    confirming_account: "Confirmation de compte",
    accounts_created: "Comptes créés",
    of: 'de',
    search: "Chercher",
    status: "Statut",
    actions: "Actions",
    add_new_user: "Ajouter un nouvel utilisateur",
    edit_user: "Modifier l'utilisateur",
    additional_emails: "Courriels supplémentaires",
    organization_letterhead: "Papier à en-tête de l'organisation",
    company_information: "Informations sur la Organisation",
    company_organization: "Organisation",
    created_at: "Créé à",
    change_main_email: "Modifier l'adresse e-mail principale",
    main_email: "Adresse e-mail principale",
    emails_created: "E-mails créés",
    add_new_additional_email: "Ajouter un nouvel e-mail supplémentaire",
    new_email_address: "Nouvelle adresse courriel",
    add_digital_letterhead: "Ajouter du papier à en-tête numérique",
    select_header_style: "Sélectionnez le style de lettre",
    header_only: "En-tête uniquement",
    header_and_footer: "En-tête et pied de page",
    email_alert: "Alerte mail",
    signature: "Signature",
    user_letterhead: "En-tête de l'utilisateur",
    user_information: "Informations de l'utilisateur",
    position: "Position",
    enable_disable_notifications: "Activer / Désactiver les notifications",
    user_name_or_email: "Username or e-mail"
  },
  errors: {
    model_error: 'Vérifiez les erreurs et réessayez',
    general_error: 'Une erreur est survenue',
    invalid_token: 'Jeton invalide',
    confirming_account_error: "Désolé, il y a un problème lors de l'activation de votre compte",
    invalid_image: "Image invalide",
    inform_crop_area: "Taille de l'image pour se conformer aux dimensions requises"
  },
  success: {
    logout_success: "Déconnexion de l'utilisateur réussie"
  },
  messages: {
    password_recovery_message: "Pour recevoir un nouveau mot de passe, entrez le nom d'utilisateur et l'adresse e-mail que vous avez utilisés pour vous inscrire",
    inform_new_password_message: "Veuillez renseigner le nouveau mot de passe",
    setting_up_account_message: "Pour activer votre compte, veuillez entrer le mot de passe et confirmer",
    confirming_account_wait: "Veuillez patienter pendant que nous confirmons votre compte",
    first_letterhead_message: "- L'en-tête doit être d'environ 700 x 200 px.",
    second_letterhead_message: "- Les formats pris en charge sont .GIF, .png, .jpeg et .jpg.",
    third_letterhead_message: "- L'en-tête de votre organisation apparaîtra en haut de tous les documents.",
    check_organization_settings_message: "Veuillez vérifier les paramètres d’en-tête de votre profil d’organisation ou contacter le support technique.",
  },
  user_menu: {
    profile: 'Profil',
    logout: 'Se déconnecter'
  },
  side_menu: {
    administration: 'Administration',
    manage_account: 'Gérer son compte',
    manage_plans: 'Gérer les forfaits',
    manage_coupons: 'Coupons',
    manage_feedbacks: 'Rétroactions',
    manage_deadlines: 'Délais',
    account: 'Compte',
    user_profile: "Profil de l'utilisateur",
    additional_users: 'Utilisateurs supplémentaires',
    organization_profile: "Profil de l'organisation",
    project_manager: 'Chef de projet',
    project_list: 'Projets',
    project_archive: 'Projets archivés',
    search_engine: "Moteur de recherche",
    by_giving_history: "En donnant l'histoire",
    by_name: 'De nom',
    by_director: 'Par réalisateur',
    by_year: 'Par année',
    by_rating: 'Par note',
    by_custom: 'Par personnalisé',
    document_generator: 'Générateur de documents',
    inquiry: 'Enquête',
    proposal: 'Proposition',
    budget: 'Budget',
    custom_letter: 'Lettre personnalisée',
    grant_secretary: 'Grant Secretary - Print',
    manage_funders: 'Gérer les bailleurs de fonds',
    favorites: 'Favoris',
    blocked_funders: 'Bloqué',
    custom_databases: 'bases de données personnalisées',
    by_keyword: 'Par mots clés',
    by_international_funding: 'Par financement international',
    thank_you: 'Thank You',
    impact_report: 'Impact Report',
  },
  buttons: {
    create_account: "Créer un compte",
    send: "Envoyer",
    reset_password: "Réinitialiser le mot de passe",
    add_user: "Ajouter un utilisateur",
    insert_user: "Insérer un utilisateur",
    save_changes: "Sauvegarder les modifications",
    update_profile: "Mettre à jour le profil",
    add_email: "Ajouter un e-mail",
    select_header_file: "Sélectionnez le fichier d'en-tête",
    select_footer_file: "Sélectionnez le fichier de pied de page",
    update_account: "Compte mis à jour",
  },
  accountCreated: "Compte créé avec succès",
  accountDisabled: "Ce compte est désactivé. Pour plus de détails, contactez l'administrateur.",
  accountDisabledSuccess: "Compte désactivé avec succès",
  accountEnabledSuccess: "Compte activé avec succès",
  accountNotActivated: "Votre compte n'est pas actif. Veuillez vérifier votre e-mail pour activer votre compte",
  accountUpdatedSuccess: "Compte mis à jour avec succès",
  additionalEmailNotFound: "E-mail supplémentaire introuvable",
  additionalEmailRemovedSuccess: "E-mail supplémentaire supprimé avec succès",
  additionalEmailSavedSuccess: "E-mail supplémentaire enregistré avec succès",
  additionalUserInsertSuccess: "Utilisateur supplémentaire ajouté avec succès",
  additionalUserUpdateSuccess: "Utilisateur supplémentaire mis à jour avec succès",
  addressBillingRequired: "La facturation de l'adresse est obligatoire",
  alertNotificationSavedSuccess: "Notification d'alerte enregistrée avec succès",
  cityBillingRequired: "La facturation de la ville est obligatoire",
  complementaryAddressBillingRequired: "Une facturation d'adresse complémentaire est requise",
  confirmRegisterUserSuccess: "Merci d'avoir confirmé votre email",
  countryBillingRequired: "La facturation du pays est obligatoire",
  countryNotFound: "Pays introuvable",
  emailExists: "L'adresse mail existe déjà",
  errorRegistration: "Le modèle est vide",
  expirationDateSavedSuccess: "Date d'expiration enregistrée avec succès",
  footerImageRequired: "L'image de pied de page est obligatoire",
  imageHeightLength: "Hauteur d'image invalide",
  imageWidthLength: "Largeur d'image invalide",
  invalidEmailAddress: "Adresse e-mail invalide",
  invalidLetterType: "Type de lettre invalide",
  invalidPhoneNumber: "Numéro de téléphone invalide",
  invalidSubscriptionPlan: "Plan d'abonnement invalide",
  invalidUserRole: "Rôle d'utilisateur invalide",
  letterheadSavedSuccess: "En-tête enregistré avec succès",
  licenseExpired: "Votre licence a expiré. Pour plus de détails, veuillez contacter l'administrateur",
  licenseNotFound: "Aucune licence suffisante n'est disponible. Veuillez contacter support@grantadvance.com",
  loginFailed: "Nom d'utilisateur ou mot de passe invalide",
  masterOnly: "Seuls les utilisateurs maîtres ont accès à cette ressource",
  notEnoughLicense: "Votre organisation n'a pas de licence disponible. Si vous souhaitez ajouter des licences, veuillez cliquer ici pour acheter des licences supplémentaires",
  notificationExists: "La notification existe déjà",
  notificationNotFound: "Notification introuvable",
  notificationTypeNotFound: "Type de notification introuvable",
  organizationDisabled: "Cette organisation est désactivée. Pour plus de détails, contactez l'administrateur.",
  organizationMainAddressUpdatedSuccess: "Adresse principale de l'organisation mise à jour avec succès",
  organizationNotFound: "Organisation introuvable",
  organizationProfileSavedSuccess: "Profil d'organisation enregistré avec succès",
  passwordModified: "Mot de passe modifié avec succès",
  passwordRecovery: "Veuillez vérifier votre messagerie pour réinitialiser votre mot de passe.",
  passwordReset: "Réinitialisation du mot de passe réussie",
  postalCodeBillingRequired: "Le code postal est obligatoire",
  profileNotFound: "Profil non trouvé",
  provinceOrStateBillingRequired: "L'état s'impose",
  provinceOrStateNotFound: "Etat introuvable",
  sendActivationLinkSuccess: "L'e-mail d'activation a été envoyé avec succès",
  sendPasswordResetSuccess: "L'e-mail de réinitialisation du mot de passe a été envoyé avec succès",
  sendSetupPasswordSuccess: "L'e-mail de configuration du mot de passe a été envoyé avec succès",
  separatedLicenceExpirationDateError: "La date d'expiration des licences séparées ne peut pas être supérieure à la date d'expiration de la licence principale",
  settingUpAccountSuccess: "Votre compte a été activé avec succès. Vous pouvez maintenant vous connecter",
  signatureSavedSuccess: "Signature enregistrée avec succès",
  subscriptionPlanNotFound: "Plan d'abonnement introuvable",
  userLogoutSuccess: "Déconnexion de l'utilisateur réussie",
  userNameExists: "Ce nom d'utilisateur est déjà pris",
  userNotAllowed: "L'utilisateur n'est pas autorisé à se connecter",
  userNotFound: "Utilisateur non trouvé",
  userProfileSavedSuccess: "Profil utilisateur enregistré avec succès",
  wrongCurrentPassword: "Mot de passe actuel incorrect",
  additionalUsersRequired: "Les utilisateurs supplémentaires sont requis",
  addressRequired: "L'adresse est obligatoire",
  amountDaysRequired: "Le nombre de jours est obligatoire",
  amountLicensesRequired: "Le nombre de licences est requis",
  amountMonthsRequired: "Le nombre de mois est requis",
  amountYearsRequired: "Le nombre d'années est requis",
  planAmountRequired: "Le montant du plan est requis",
  billingAddressLine2Required: "L'adresse de facturation complémentaire est obligatoire",
  billingAddressRequired: "L'adresse de facturation est obligatoire",
  billingCityRequired: "La ville de facturation est obligatoire",
  billingCountryRequired: "La facturation du pays est obligatoire",
  billingPostalCodeRequired: "Le code postal est obligatoire",
  billingProvinceOrStateRequired: "La province/l'état de facturation est obligatoire",
  cityRequired: "La ville s'impose",
  companyNameRequired: "Le nom de l'entreprise est obligatoire",
  businessEinRequired: "BN/EIN est obligatoire",
  compareConfirmPassword: "Le mot de passe et le mot de passe de confirmation ne correspondent pas.",
  complementaryAddressRequired: "L'adresse complémentaire est obligatoire",
  confirmPasswordRequired: "Le mot de passe de confirmation est requis",
  countryRequired: "Le pays est requis",
  displayPlanRequired: "Le plan d'affichage est obligatoire",
  emailRequired: "L'e-mail est obligatoire",
  firstNameRequired: "Le prénom est obligatoire",
  headerImageRequired: "L'image d'en-tête est obligatoire",
  invalidEmail: "Cette adresse email est invalide",
  invalidRole: "Rôle d'utilisateur invalide",
  lastNameRequired: "Le nom de famille est obligatoire",
  licenseAmountRange: "Le nombre de licences doit être supérieur à zéro",
  licenseRequired: "La licence est obligatoire",
  newPasswordRequired: "Le nouveau mot de passe est requis",
  notificationTypeRequired: "Le type de notification est requis",
  oldPasswordRequired: "L'ancien mot de passe est requis",
  organizationIdRequired: "L'identifiant de l'organisation est requis",
  passwordLength: "Le {0} doit contenir au moins {2} et au maximum {1} caractères.",
  passwordRequired: "Le mot de passe est obligatoire",
  phoneRequired: "Le numéro de téléphone est obligatoire",
  planDescriptionRequired: "La description du plan est obligatoire",
  postalCodeRequired: "Le code postal est obligatoire",
  provinceOrStateRequired: "L'état s'impose",
  roleRequired: "Le rôle est requis",
  signatureRequired: "La signature est obligatoire",
  subscriptionPlanNameRequired: "Le plan d'abonnement est obligatoire",
  tokenRequired: "Le jeton est requis",
  userIdRequired: "L'ID utilisateur est requis",
  userNameRequired: "Le e-mail est requis",
  planNameRequired: "Le nom du plan interne est requis",
  customLetterRequired: "La lettre personnalisée est obligatoire",
  customLetterSavedSuccess: "Lettre personnalisée enregistrée avec succès",
  customTagDeleteSuccess: "Balise personnalisée supprimée avec succès",
  customTagInsertSuccess: "Balise personnalisée créée avec succès",
  customTagNotFound: "Tag personnalisé introuvable",
  customTagUpdateSuccess: "Balise personnalisée mise à jour avec succès",
  documentStateInsertSuccess: "État du document créé avec succès",
  inquiryLetterRequired: "La lettre de demande est obligatoire",
  inquiryLetterSavedSuccess: "Lettre de demande enregistrée avec succès",
  proposalLetterRequired: "La lettre de proposition est obligatoire",
  proposalLetterSavedSuccess: "Lettre de proposition enregistrée avec succès",
  userProjectRequired: "Le projet utilisateur est obligatoire",
  customDocumentTypeRequired: "Le type de document est obligatoire",
  documentAmountRequired: "Le montant est requis",
  documentFlaggedRequired: "La date est obligatoire",
  documentStateRequired: "L'état du document est obligatoire",
  tagDescriptionRequired: "La description de la balise est obligatoire",
  tagNameRequired: "Le nom de la balise est obligatoire",
  blockedFundersDeleteAllSuccess: "Tous les bailleurs de fonds ont été débloqués avec succès",
  blockedFundersDeleteSuccess: "Fondateur débloqué avec succès",
  blockedFundersInsertSuccess: "Fondateur bloqué avec succès",
  blockedFundersNotFound: "Bailleur de fonds bloqué introuvable",
  customDatabaseDeleteSuccess: "Base de données personnalisée supprimée avec succès",
  customDatabaseFieldsMapRequired: "S'il vous plaît. Configurez tous les champs obligatoires avant de continuer",
  customDatabaseNotFound: "Base de données personnalisée introuvable",
  customDatabaseUserFieldsEmpty: "La liste des champs de configuration est vide",
  favoriteDeleteSuccess: "Favori supprimé avec succès",
  favoriteDuplicateSuccess: "Favori dupliqué avec succès",
  favoriteFundersDeleteSuccess: "Le bailleur de fonds préféré a été supprimé avec succès",
  favoriteFundersInsertSuccess: "{{fundersAmount}} bailleurs de fonds ajoutés à la liste des bailleurs de fonds {{favoriteName}}",
  favoriteFundersNotFound: "Bailleur de fonds préféré introuvable",
  favoriteFundersUpdateSuccess: "Mise à jour du bailleur de fonds préféré avec succès",
  favoriteInsertSuccess: "Favori créé avec succès",
  favoriteNotFound: "Favori introuvable",
  favoriteUpdateSuccess: "Mise à jour favorite réussie",
  fundersListEmpty: "La liste des bailleurs de fonds est vide",
  noteDeleteSuccess: "Note supprimée avec succès",
  noteInsertSuccess: "Note créée avec succès",
  noteNotFound: "Note introuvable",
  noteUpdateSuccess: "Note mise à jour avec succès",
  noteRequired: "Une note est requise avant de joindre des documents",
  notificationDateRequired: "La date de notification est obligatoire",
  emailListRequired: "Sélectionnez l'e-mail",
  customDatabaseFileRequired: "Le fichier excel est obligatoire",
  favoriteNameRequired: "Le nom du favori est requis",
  favoriteRequired: "Le favori est obligatoire",
  foundationRequired: "La fondation s'impose",
  jsonUserFieldsMapRequired: "S'il vous plaît. Configurez tous les champs obligatoires avant de continuer",
  labelTypeRequired: "Le type d'étiquette est obligatoire",
  letterTypeRequired: "Sélectionner le type de document à imprimer",
  noteDescriptionRequired: "Le descriptif est obligatoire",
  noteTitleRequired: "Le titre est obligatoire",
  bundleInsertSuccess: "Bundle créé avec succès",
  bundleNotFound: "Groupe introuvable",
  bundleTaskCount: "Entrez au moins une tâche",
  bundleTaskNotFound: "Une ou plusieurs tâches sont introuvables",
  flagDateRequired: "La date est obligatoire",
  flagUpdateSuccess: "Drapeau mis à jour avec succès",
  projectBudgetCategoryDescriptionRequired: "La description de la catégorie est obligatoire",
  projectBudgetDeleteSuccess: "Budget supprimé avec succès",
  projectBudgetDescriptionExists: "Cette description existe déjà",
  projectBudgetInsertSuccess: "Budget ajouté avec succès",
  projectBudgetNotFound: "Budget introuvable",
  projectBudgetResetSuccess: "Budget réinitialisé avec succès",
  projectBudgetUpdateSuccess: "Budget mis à jour avec succès",
  projectEndDateRequired: "La date de fin est obligatoire",
  projectFundersDeleteSuccess: "Project Funder supprimé avec succès",
  projectFundersEmpty: "Veuillez sélectionner au moins un bailleur de fonds avant de continuer",
  projectFundersInsertSuccess: "{{funderAmount}} bailleurs de fonds ajoutés au {{projectName}}",
  projectStartDateRequired: "La date de début est obligatoire",
  taskDeleteSuccess: "Tâche supprimée avec succès",
  taskEditSuccess: "Tâche mise à jour avec succès",
  taskInsertSuccess: "Tâche créée avec succès",
  taskListEmpty: "La liste des tâches est vide",
  taskNotFound: "Tâche introuvable",
  userProjectArchivedSuccess: "Projet utilisateur archivé avec succès",
  userProjectDeleteSuccess: "Projet utilisateur supprimé avec succès",
  userProjectEndDateRequired: "La date de fin est obligatoire",
  userProjectInsertSuccess: "Projet utilisateur créé avec succès",
  userProjectListEmpty: "Sélectionner un ou plusieurs projets",
  userProjectNotFound: "Projet introuvable",
  userProjectStartDateInvalid: "La date de début est invalide",
  userProjectStartDateRequired: "La date de début est obligatoire",
  userProjectUnarchivedSuccess: "Projet utilisateur désarchivé avec succès",
  userProjectUpdateSuccess: "Projet utilisateur mis à jour avec succès",
  budgetTypeInvalid: "Le type de budget n'est pas valide",
  bundleDescriptionRequired: "La description du bundle est obligatoire",
  bundleNameRequired: "Le nom du bundle est requis",
  bundleTaskRequired: "La liste des tâches est obligatoire",
  flagAmountInvalid: "Le montant est invalide",
  flagAmountRequired: "Le montant est requis",
  flagStatusRequired: "Le statut est requis",
  foundationNameRequired: "Le nom de la fondation est obligatoire",
  foundationSourceTypeRequired: "La source de la fondation est obligatoire",
  profileIdRequired: "Le profil est obligatoire",
  projectBudgetAmountRequired: "Le montant du budget est requis",
  projectBudgetDescriptionRequired: "La description du budget est obligatoire",
  projectCostInvalid: "Le coût du projet est invalide",
  projectCostRequired: "Le coût du projet est obligatoire",
  projectDescriptionRequired: "La description du projet est obligatoire",
  projectNameRequired: "Le nom du projet est obligatoire",
  projectStatusInvalid: "Le statut du projet est invalide",
  taskDescriptionRequired: "La description de la tâche est obligatoire",
  taskNameRequired: "Le nom de la tâche est obligatoire",
  foundationInsertSuccess: "Fondation ajoutée avec succès",
  savedSearchDeleteSuccess: "Recherche supprimée avec succès",
  savedSearchDescriptionExists: "Cette description existe déjà",
  savedSearchInsertSuccess: "Recherche ajoutée avec succès",
  savedSearchNotFound: "Recherche enregistrée introuvable",
  savedSearchTypeInvalid: "Le type de recherche enregistré n'est pas valide",
  savedSearchUpdateSuccess: "Recherche mise à jour avec succès",
  descriptionRequired: "Le descriptif est obligatoire",
  jsonFieldsRequired: "Le champ de recherche est obligatoire",
  searchTypeRequired: "Le type de recherche est obligatoire",
  userProjectCompleted: "Ce projet est marqué comme terminé",
  sendOnlineApplicationSuccess: "Message de candidature en ligne envoyé avec succès",
  sendSuggestionSuccess: "Suggestion envoyée avec succès",
  urlRequired: "L'url est obligatoire",
  noteSubjectRequired: "Le sujet est obligatoire",
  accountDeleted: "Ce compte a été supprimé. Contactez l'administrateur",
  userRemovedSuccess: "Compte supprimé avec succès",
  userNameOrEmailRequired: "The User or e-mail name is required",
  fieldRequired: "Ce champ est obligatoire",
  pickupNewPassword: "Veuillez prendre un nouveau mot de passe",
  passwordMinCharacter: "Veuillez saisir au moins {{minimumCharaters}} caractères",
  enterSameValueAgain: "Veuillez saisir à nouveau la même valeur",
  feedbackSubmitted: "Commentaires soumis avec succès",
  feedbackReviewUpdated: "Mise à jour de l’avis",
  announcementDescriptionRequired: "La description de l'annonce est obligatoire",
  announcementReleaseDateRequired: "La date de sortie de l'annonce est requise",
  announcementReleaseDateInvalid: "Past dates are not allowed for the Release date",
  announcementTypeRequired: "Le type d'annonce est obligatoire",
  announcementNotFound: "Annonce introuvable",
  announcementInsertSuccess: "Annonce ajoutée avec succès",
  announcementUpdateSuccess: "Annonce mise à jour avec succès",
  announcementDeleteSuccess: "Annonce supprimée avec succès",
  notificationNotFound: "Notification not found",
  couponNameRequired: "Le nom du coupon est obligatoire",
  couponCodeRequired: "Le code promo est requis",
  couponCodeFormatInvalid: "Le code promo n’est pas valide",
  codeLengthInvalid: "Maximum de 15 caractères",
  couponExpirationDateRequired: "La date ne peut pas être postérieure à plus de 6 mois.",
  couponExpirationMaxValue: "La fecha no puede ser mayor a 6 meses a partir de hoy",
  couponExpirationDateInvalid: "Date d’expiration non valide",
  couponSubscriptionPlanRequired: "Le plan d’abonnement au coupon est requis",
  couponExtraMonthsAmountInvalid: "Le mois supplémentaire ne permet que le numéro 1 à 99",
  couponDiscountAmountInvalid: "La réduction ne permet que le numéro 1 à 90",
  couponCodeAlreadyExist: "Le code promo a déjà été utilisé",
  couponSubscriptionNotFound: "La formule d’abonnement est introuvable",
  couponNotFound: "Coupon introuvable",
  couponSelectType: "Veuillez choisir entre le temps supplémentaire ou la réduction",
  couponInsertSuccess: "Coupon ajouté avec succès",
  couponUpdateSuccess: "Coupon mis à jour avec succès",
  couponDeleteSuccess: "Le coupon a été supprimé avec succès",
  couponPauseUpdateSuccess: "Coupon mis à jour avec succès",
  deadlineEINRequired: "L’EIN est requis",
  deadlineFoundationNameRequired: "Le nom de la fondation est obligatoire",
  deadlineCategoryRequired: "La catégorie est obligatoire",
  deadlineURLRequired: "L’URL de la date limite est requise",
  deadlineCountryRequired: "Le pays est requis",
  deadlineDateInvalid: "Permettre uniquement les dates futures",
  deadlineAmountToobig: "Le montant de la subvention est trop élevé",
  deadlineImportSuccess: "{{fileName}} importé avec succès",
  deadlineUpdateSuccess: "Date limite mise à jour avec succès",
  deadlineDeleteSuccess: "Fecha límite eliminada con éxito",
  thankYouLetterSavedSuccess: "Thank you letter saved with success",
  impactReportLetterSavedSuccess: "Impact report letter saved with success",
  historyTypeRequired: "The history type is required",
  startDateRequired: "La date de début est obligatoire",
  startDateInvalid: "Veuillez sélectionner une date postérieure à aujourd'hui",
  funderQualifiedSuccess: "Funder qualified successfully",
  funderDisqualifiedSuccess: "Funder disqualified successfully",
};
