import { emptySplitApi } from './index'

export const foundationProfileCanadianApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMainProfileCA: builder.mutation({
        query: (model) => ({
            url: `api/v1/search-engine-ca-service/foundation-profile/main/${model.profileId}`,
            method: "GET",
        }),
    }),
    getGiftsPagedListCAV2: builder.mutation({
        query: (model) => ({
            url: `api/v2/search-engine-ca-service/foundation-profile/gifts/${model.profileId}`,
            method: "POST",
            body: model
        }),
    }),
    getGiftsPagedListCA: builder.mutation({
        query: (model) => ({
            url: `api/v1/search-engine-ca-service/foundation-profile/gifts/${model.profileId}`,
            method: "POST",
            body: model
        }),
    }),
    getProgramListCA: builder.query({
        query: (profileId) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/programs/${profileId}`,
          method: "GET",
        }),
    }),
    getApplicationCA: builder.query({
      query: (profileId) => ({
        url: `api/v1/search-engine-ca-service/foundation-profile/applications/${profileId}`,
        method: "GET",
      }),
    }),
    getApplicationWithTriggerCA: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/applications/${profileId}`,
          method: "GET",
        }),
      }),
    getGrantOverviewCA: builder.mutation({
        query: (model) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/grant-overview/${model.profileId}?`
                + `pageNumber=${model?.pageNumber || 0}`
                + `&pageSize=${model?.pageSize || ''}`
                + `&orderField=${model?.orderField || 'description'}`
                + `&orderDirection=${model?.orderDirection || 'asc'}`,
          method: "GET",
        }),
    }),
    getDirectorListCA: builder.mutation({
        query: (model) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/directors/${model.profileId}?`
                + `pageNumber=${model?.pageNumber || 0}`
                + `&pageSize=${model?.pageSize || ''}`
                + `&orderField=${model?.orderField || 'description'}`
                + `&orderDirection=${model?.orderDirection || 'asc'}`,
          method: "GET",
        }),
    }),
   
    getChartProvinceListCA: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/charts/province-list/${profileId}`,
          method: "GET",
        }),
    }),
    getChartRangeAmountListCA: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/charts/range-amount/${profileId}`,
          method: "GET",
        }),
    }),
    getChartRangeSectorCA: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/charts/range-sector/${profileId}`,
          method: "GET",
        }),
    }),
    getFoundationProfileSummaryOverviewCA: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/summary-overview/${profileId}`,
          method: "GET",
        }),
    }),
    getCustomAnalysisListCA: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/custom-analysis/${profileId}`,
          method: "GET",
        }),
    }),
    getFoundationFinancialList: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/financial-list/${profileId}`,
          method: "GET",
        }),
    }),
    getYearAnalysisList: builder.mutation({
        query: (profileId) => ({
          url: `api/v1/search-engine-ca-service/foundation-profile/year-analysis/${profileId}`,
          method: "GET",
        }),
    }),
  }),
  overrideExisting: false,
});

export const {
    useGetMainProfileCAMutation, 
    useGetGiftsPagedListCAMutation,
    useGetGiftsPagedListCAV2Mutation,
    useGetProgramListCAQuery,
    useGetApplicationCAQuery,
    useGetApplicationWithTriggerCAMutation,
    useGetGrantOverviewCAMutation,
    useGetDirectorListCAMutation,
    useGetChartProvinceListCAMutation,
    useGetChartRangeAmountListCAMutation,
    useGetChartRangeSectorCAMutation,
    useGetFoundationProfileSummaryOverviewCAMutation,
    useGetCustomAnalysisListCAMutation,
    useGetFoundationFinancialListMutation,
    useGetYearAnalysisListMutation
} = foundationProfileCanadianApi;
