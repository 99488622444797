import { emptySplitApi } from "./index";

export const deadlineApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeadlinePagedList: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/deadline?`
                        + `&pageNumber=${model?.pageNumber || 0}`
                        + `&pageSize=${model?.pageSize || 10}`
                        + `&orderField=${model?.orderField || 'Name'}`
                        + `&orderDirection=${model?.orderDirection || 'asc'}`,
                method: "GET",
              }),
        }),
        importDeadline: builder.mutation({
            query: (model) => ({
                url: `api/v1/foundation-service/deadline/import`,
                method: "POST",
                body: model,
            }),
        }),
        updateDeadline: builder.mutation({
            query: ({id, ...model}) => ({
                url: `api/v1/foundation-service/deadline/${id}`,
                method: "PATCH",
                body: model,
            }),
        }),
        deleteDeadline: builder.mutation({
            query: (id) => ({
                url: `api/v1/foundation-service/deadline/${id}`,
                method: "DELETE",
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetDeadlinePagedListMutation,
    useImportDeadlineMutation,
    useUpdateDeadlineMutation,
    useDeleteDeadlineMutation,
} = deadlineApi;
