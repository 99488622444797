import { createSlice } from "@reduxjs/toolkit";
import { thankYouLetterApi } from "Services/ThankYouLetterService";

export const initialState = {
    id: null,
    userProject: {
        id: '',
        value: '',
        label: ''
    },
    activeStep: 0,
    thankYouDocument: {
        introduction: '',
        customizedContent: '',
        reinforceImpact: '',
        nextSteps: '',
        conclusion: '',
    },
    stepList: [],
    forceDisabled: false,
    autosaveEnabled: false,
    timeoutId: null,
};

const thankYouSlice = createSlice({
    name: "document-generator-thank-you",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            state.userProject = action.payload;
        },
        handleConfirmChange: (state, action) => {
            state.userProject = state.userProjectToChange;
        },
        handleStep: (state, action) => {
            state.activeStep = action.payload;
        },
        handleChangeDocument: (state, action) => {
            state.thankYouDocument[action.payload.stepName] = action.payload.document;
        },
        handleSaveSuccess: (state, action) => {
            state.id = action.payload.data;
        },
        handleAutoSaveEnabled: (state, action) => {
            state.autosaveEnabled = action.payload;
        },
        handleClear: (state, action) => {
            state.id = null;
            state.activeStep = 0;
            state.userProject.id = '';
            state.userProject.value = '';
            state.userProject.label = '';
            state.autosaveEnabled = false;
            state.thankYouDocument.introduction = "";
            state.thankYouDocument.customizedContent = "";
            state.thankYouDocument.reinforceImpact = "";
            state.thankYouDocument.nextSteps = "";
            state.thankYouDocument.conclusion = "";
            
        },
        handleFillAllProjects: (state, action) => {
            const { selectedProject, projects } = action.payload;
            state.forceDisabled = action.payload.length === 0;
            state.autosaveEnabled = false;
            state.allProjectList = projects ?? [];
            state.userProject = selectedProject;
        },
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            thankYouLetterApi.endpoints.getThankYouStepList.matchFulfilled, (state, action) => {
                state.stepList = action.payload;
            }
        )
        .addMatcher(
            thankYouLetterApi.endpoints.getThankYouLetter.matchFulfilled, (state, action) => {
                state.id = action.payload.id;
                state.thankYouDocument.introduction = action.payload.introduction;
                state.thankYouDocument.customizedContent = action.payload.customizedContent;
                state.thankYouDocument.reinforceImpact = action.payload.reinforceImpact;
                state.thankYouDocument.nextSteps = action.payload.nextSteps;
                state.thankYouDocument.conclusion = action.payload.conclusion;
                state.thankYouDocument.notesQuantity = action.payload.notesQuantity;
            }
        )
    }

});

export const { 
    handleChangeUserProject,
    handleStep,
    handleChangeDocument,
    handleSaveSuccess,
    handleAutoSaveEnabled,
    handleClear,
} = thankYouSlice.actions;

export default thankYouSlice.reducer;
