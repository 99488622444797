import { useEffect } from 'react';

/**
 * @param {string} name - Identifier.
 * @param {Function} composeUrl - A function that returns an object of type URL object based on the provided parameters.
 */
const useVersioning = (name, composeUrl) => {

    const paths = {
        "by-giving-history": {
            v1: "main/search/by-giving-history",
            v2: "main/search/giving-history-v2"
        },
        "by-keyword": {
            v1: "main/search/by-keyword",
            v2: "main/search/by-keyword-v2"
        },
        "by-name": {
            v1: "main/search/by-name",
            v2: "main/search/by-name-v2"
        },
        "by-director": {
            v1: "main/search/by-director",
            v2: "main/search/by-director-v2"
        },
        "by-year": {
            v1: "main/search/by-year",
            v2: "main/search/by-year-v2"
        },
        "by-giving-history-ca": {
            v1: "main/search/giving-history-ca",
            v2: "main/search/giving-history-ca-v2"
        },
        "by-name-ca": {
            v1: "main/search/name-ca",
            v2: "main/search/name-ca-v2"
        },
        "by-director-ca": {
            v1: "main/search/directors-ca",
            v2: "main/search/directors-ca-v2"
        },
        "by-year-ca": {
            v1: "main/search/year-ca",
            v2: "main/search/year-ca-v2"
        },
        "by-international-funding": {
            v1: "main/search/by-international-funding",
            v2: "main/search/by-international-funding-v2"
        },
        "foundation-profile": {
            v1: "main/foundation-profile",
            v2: "main/foundation-profile-v2"
        },
        "foundation-profile-ca": {
            v1: "main/foundation-profile-ca",
            v2: "main/foundation-profile-ca-v2"
        }
    }

    const comparePaths = (path1, path2) => {
        const parts1 = path1.replace(/^\/|\/$/g, '').split('/');
        const parts2 = path2.replace(/^\/|\/$/g, '').split('/');
      
        let isSame = true;
      
        // Compare each part
        const maxLength = Math.max(parts1.length, parts2.length);
        for (let i = 0; i < maxLength; i++) {
          const part1 = parts1[i] || '';
          const part2 = parts2[i] || '';
          if (part1 !== part2) {
            isSame = false;
          }
        }
      
        return isSame;
    }

    const getPathByName = (name) => {
        let version = localStorage.getItem(name);
        if(version === null) return null;

        return paths[name][version];
    }

    const toggleVersion = () => {
        let toVersion = "v1";
        let currentVersion = localStorage.getItem(name);

        if(currentVersion !== null) 
            toVersion = currentVersion === "v1" ? "v2" : "v1";

        localStorage.setItem(name, toVersion);

        window.location = typeof composeUrl === "function" 
            ? composeUrl(paths[name][toVersion])
            : new URL(paths[name][toVersion], window.location.origin);
    }

    useEffect(() => {
        const value = localStorage.getItem(name);
        if(value === null) return;

        const targetPath = typeof composeUrl === "function" 
        ? composeUrl(paths[name][value])
        : new URL(paths[name][value], window.location.origin);

        const isSame = comparePaths(window.location.pathname, targetPath.pathname);

        if(!isSame) {
            window.location = targetPath
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { toggleVersion, getPathByName }
}

export default useVersioning;