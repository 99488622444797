export const TRANSLATIONS_ES_MX = {
  labels: {
    user_name: 'Nombre de usuario',
    password: 'Contraseña',
    reset_password: 'Nueva contraseña',
    reset_password_confirm: 'Confirmar contraseña',
    login: 'Iniciar Sesión',
    sign_up: "Inscribirse",
    forgot_password: "Olvidaste tu contraseña?",
    go_back: "Regresa",
    organization: "Organización",
    phone: "Teléfono",
    first_name: "Primer nombre",
    last_name: "Apellido",
    address: "Dirección",
    select: "Seleccione",
    country: "País",
    complementary_address: "Dirección complementaria",
    postal_code: "Código postal",
    city: "Ciudad",
    state: "Estado",
    stateOrProvince: "Estado/Provincia",
    billing: "Facturación",
    use_same_address: "Usar la misma dirección",
    loading: "Cargando...",
    register: "Registrarse",
    message: "Mensaje",
    error: "Error",
    welcome_back: "Hola, bienvenido de nuevo",
    enter_credentials: "Ingrese sus credenciales para continuar",
    dont_have_account: "¿No tienes cuenta?",
    remember_me: "Recuérdame",
    sign_in: "Registrarse",
    already_have_account: "¿Ya tienes una cuenta?",
    subscription_plan: "Plan de suscripción",
    country_billing: "facturación del país",
    password_recovery: "Recuperación de contraseña",
    password_reset: "Restablecimiento de contraseña",
    setting_up_account: "Configuración de cuenta",
    current_password: "Contraseña actual",
    new_password: "Nueva contraseña",
    confirm_password: "Confirmar contraseña",
    confirming_account: "Confirmando cuenta",
    accounts_created: "Cuentas creadas",
    of: 'de',
    search: "Búsqueda",
    status: "Estado",
    actions: "Comportamiento",
    add_new_user: "Añadir nuevo usuario",
    edit_user: "Editar usuario",
    additional_emails: "Correos electrónicos adicionales",
    organization_letterhead: "Membrete de la organización",
    company_information: "Información de la Organización",
    company_organization: "Organización",
    created_at: "Creado en",
    change_main_email: "Cambiar la dirección de correo electrónico principal",
    main_email: "Dirección de correo electrónico principal",
    emails_created: "Correos electrónicos creados",
    add_new_additional_email: "Añadir nuevo e-mail adicional",
    new_email_address: "Nueva dirección de correo electrónico",
    add_digital_letterhead: "Agregar membrete digital",
    select_header_style: "Seleccionar estilo de letra",
    header_only: "Solo encabezado",
    header_and_footer: "Encabezado y pié de página",
    email_alert: "Alerta de correo",
    signature: "Firma",
    user_letterhead: "Membrete del usuario",
    user_information: "Informacion del usuario",
    position: "Posición",
    enable_disable_notifications: "Activar/Desactivar notificaciones",
    user_name_or_email: "Username or e-mail"
  },
  errors: {
    model_error: 'Compruebe si hay errores y vuelva a intentarlo',
    general_error: 'Se ha producido un error',
    invalid_token: 'Simbolo no valido',
    confirming_account_error: "Lo sentimos, hay un problema al activar su cuenta",
    invalid_image: "Imagen inválida",
    inform_crop_area: "Tamaño de la imagen para ajustarse a las dimensiones requeridas"
  },
  success: {
    logout_success: 'Cierre de sesión de usuario con éxito'
  },
  messages: {
    password_recovery_message: "Para recibir una nueva contraseña, ingrese el nombre de usuario y la dirección de correo electrónico que utilizó para registrarse",
    inform_new_password_message: "Por favor, informe la nueva contraseña",
    setting_up_account_message: "Para activar su cuenta, ingrese la contraseña y confirme",
    confirming_account_wait: "Espere mientras confirmamos su cuenta",
    first_letterhead_message: "- El membrete debe ser aproximadamente 700 x 200 px.",
    second_letterhead_message: "- Los formatos admitidos son .GIF, .png, .jpeg y .jpg.",
    third_letterhead_message: "- El membrete de su organización aparecerá en la parte superior de todos los documentos",
    check_organization_settings_message: "Compruebe la configuración del membrete del perfil de su organización o póngase en contacto con el servicio de asistencia.",
  },
  user_menu: {
    profile: 'Perfil',
    logout: 'Cerrar sesión'
  },
  side_menu: {
    administration: 'Administración',
    manage_account: 'Administrar cuenta',
    manage_plans: 'Gestionar planes',
    manage_announcement: 'Plan de Suscripción',
    manage_subscription_plan: '',
    manage_coupons: 'Cupones',
    manage_feedbacks: 'Comentarios',
    manage_deadlines: 'Plazos',
    account: 'Cuenta',
    user_profile: 'Perfil del usuario',
    additional_users: 'Usuarios adicionales',
    organization_profile: 'Perfil de la organización',
    project_manager: 'Gerente de proyecto',
    project_list: 'Proyectos',
    project_archive: 'Proyectos archivados',
    search_engine: "Buscador",
    by_giving_history: 'Al dar la historia',
    by_name: 'Por nombre',
    by_director: 'Por la directora',
    by_year: 'Por año',
    by_rating: 'Por calificación',
    by_custom: 'Por encargo',
    document_generator: 'Generador de documentos,',
    inquiry: 'Consulta',
    proposal: 'Propuesta',
    budget: 'Presupuesto',
    custom_letter: 'Carta personalizada',
    grant_secretary: 'Grant Secretary - Print',
    manage_funders: 'Administrar financiadores',
    favorites: 'Favoritos',
    blocked_funders: 'Obstruido',
    custom_databases: 'bases de datos personalizadas',
    by_keyword: 'Por palabras clave',
    by_internacional_funding: 'Por financiación internacional',
    thank_you: 'Thank You',
    impact_report: 'Impact Report',
  },
  buttons: {
    create_account: "Crear una cuenta",
    send: "Enviar",
    reset_password: "Restablecer la contraseña",
    add_user: "Agregar usuario",
    insert_user: "Insertar usuario",
    save_changes: "Guardar cambios",
    update_profile: "Actualización del perfil",
    add_email: "Agregar correo electrónico",
    select_header_file: "Seleccionar archivo de encabezado",
    select_footer_file: "Seleccionar archivo de pie de página",
    update_account: "Actualizar cuenta",
  },
  accountCreated: "Cuenta creada con éxito",
  accountDisabled: "Esta cuenta está deshabilitada. Para obtener más detalles, comuníquese con el administrador.",
  accountDisabledSuccess: "Cuenta deshabilitada con éxito",
  accountEnabledSuccess: "Cuenta habilitada con éxito",
  accountNotActivated: "Su cuenta no está activa. Por favor revise su correo electrónico para activar su cuenta",
  accountUpdatedSuccess: "Cuenta actualizada correctamente",
  additionalEmailNotFound: "Correo electrónico adicional no encontrado",
  additionalEmailRemovedSuccess: "Correo electrónico adicional eliminado con éxito",
  additionalEmailSavedSuccess: "Correo electrónico adicional guardado con éxito",
  additionalUserInsertSuccess: "Usuario adicional añadido con éxito",
  additionalUserUpdateSuccess: "Usuario adicional actualizado correctamente",
  addressBillingRequired: "Se requiere dirección de facturación",
  alertNotificationSavedSuccess: "Notificación de alerta guardada con éxito",
  cityBillingRequired: "Se requiere la facturación de la ciudad",
  complementaryAddressBillingRequired: "Se requiere facturación de dirección complementaria",
  confirmRegisterUserSuccess: "Gracias por confirmar tu correo electrónico",
  countryBillingRequired: "El país de facturación es obligatorio",
  countryNotFound: "País no encontrado",
  emailExists: "La dirección de correo ya existe",
  errorRegistration: "El modelo está vacío",
  expirationDateSavedSuccess: "Fecha de caducidad guardada con éxito",
  footerImageRequired: "La imagen de pie de página es obligatoria",
  imageHeightLength: "Altura de imagen no válida",
  imageWidthLength: "Ancho de imagen no válido",
  invalidEmailAddress: "Dirección de correo electrónico no válida",
  invalidLetterType: "Tipo de letra no válido",
  invalidPhoneNumber: "Numero de telefono invalido",
  invalidSubscriptionPlan: "Plan de suscripción no válido",
  invalidUserRole: "Rol de usuario no válido",
  letterheadSavedSuccess: "Membrete guardado con exito",
  licenseExpired: "Su licencia ha caducado. Para más detalles, póngase en contacto con el administrador",
  licenseNotFound: "No hay suficientes licencias disponibles. Póngase en contacto con support@grantadvance.com",
  loginFailed: "Usuario o contraseña invalido",
  masterOnly: "Solo los usuarios maestros tienen acceso a este recurso",
  notEnoughLicense: "Su organización no tiene ninguna licencia disponible. Si desea agregar licencias, haga clic aquí para comprar licencias adicionales",
  notificationExists: "La notificación ya existe",
  notificationNotFound: "Notificación no encontrada",
  notificationTypeNotFound: "Tipo de notificación no encontrado",
  organizationDisabled: "Esta organización está deshabilitada. Para obtener más detalles, comuníquese con el administrador.",
  organizationMainAddressUpdatedSuccess: "Dirección principal de la organización actualizada con éxito",
  organizationNotFound: "Organización no encontrada",
  organizationProfileSavedSuccess: "Perfil de la organización guardado con éxito",
  passwordModified: "Contraseña modificada con éxito",
  passwordRecovery: "Por favor revise su correo electrónico para restablecer su contraseña.",
  passwordReset: "Restablecimiento de contraseña con éxito",
  postalCodeBillingRequired: "El código postal es obligatorio",
  profileNotFound: "Perfil no encontrado",
  provinceOrStateBillingRequired: "Se requiere el estado",
  provinceOrStateNotFound: "Estado no encontrado",
  sendActivationLinkSuccess: "El correo electrónico de activación se ha enviado con éxito",
  sendPasswordResetSuccess: "El correo electrónico de restablecimiento de contraseña se ha enviado correctamente",
  sendSetupPasswordSuccess: "El correo electrónico de configuración de la contraseña se ha enviado correctamente",
  separatedLicenceExpirationDateError: "La fecha de vencimiento de las licencias separadas no puede ser posterior a la fecha de vencimiento de la licencia principal",
  settingUpAccountSuccess: "Su cuenta ha sido activada con éxito. Ahora puede iniciar sesión",
  signatureSavedSuccess: "Firma guardada con éxito",
  subscriptionPlanNotFound: "Plan de suscripción no encontrado",
  userLogoutSuccess: "Cierre de sesión del usuario con éxito",
  userNameExists: "Este nombre de usuario ya está en uso",
  userNotAllowed: "Usuario no autorizado para iniciar sesión",
  userNotFound: "Usuario no encontrado",
  userProfileSavedSuccess: "Perfil de usuario guardado con éxito",
  wrongCurrentPassword: "Contraseña actual incorrecta",
  additionalUsersRequired: "Se requieren los usuarios adicionales",
  addressRequired: "La dirección es requerida",
  amountDaysRequired: "Se requiere la cantidad de días",
  amountLicensesRequired: "La cantidad de licencias es obligatoria",
  planAmountRequired: "El monto del plan es obligatorio",
  amountMonthsRequired: "Se requiere la cantidad de meses",
  amountYearsRequired: "Se requiere la cantidad de años",
  billingAddressLine2Required: "Se requiere la dirección de facturación complementaria",
  billingAddressRequired: "La dirección de facturación es obligatoria",
  billingCityRequired: "La ciudad de facturación es obligatoria",
  billingCountryRequired: "El país de facturación es obligatorio",
  billingPostalCodeRequired: "El código postal es obligatorio",
  billingProvinceOrStateRequired: "Se requiere la provincia/estado de facturación",
  cityRequired: "Se requiere la ciudad",
  companyNameRequired: "El nombre de la empresa es obligatorio",
  businessEinRequired: "BN/EIN es obligatorio",
  compareConfirmPassword: "La contraseña y la contraseña de confirmación no coinciden.",
  complementaryAddressRequired: "Se requiere la dirección complementaria",
  confirmPasswordRequired: "Se requiere la contraseña de confirmación",
  countryRequired: "Se requiere el país",
  displayPlanRequired: "El plano de visualización es obligatorio",
  emailRequired: "El correo electrónico es obligatorio",
  firstNameRequired: "El primer nombre es obligatorio",
  headerImageRequired: "La imagen del encabezado es obligatoria",
  invalidEmail: "Este email es invalido",
  invalidRole: "Rol de usuario no válido",
  lastNameRequired: "El apellido es obligatorio",
  licenseAmountRange: "El número de licencias debe ser mayor que cero",
  licenseRequired: "Se requiere la licencia",
  newPasswordRequired: "Se requiere la nueva contraseña",
  notificationTypeRequired: "Se requiere el tipo de Notificación",
  oldPasswordRequired: "Se requiere la contraseña anterior",
  organizationIdRequired: "El ID de la organización es obligatorio",
  passwordLength: "El {0} debe tener al menos {2} y un máximo de {1} caracteres de longitud.",
  passwordRequired: "Se requiere la contraseña",
  phoneRequired: "El número de teléfono es obligatorio",
  planDescriptionRequired: "La descripción del plan es obligatoria",
  postalCodeRequired: "El código postal es obligatorio",
  provinceOrStateRequired: "Se requiere el estado",
  roleRequired: "El rol es requerido",
  signatureRequired: "Se requiere la firma",
  subscriptionPlanNameRequired: "Se requiere el plan de suscripción",
  tokenRequired: "Se requiere el token",
  userIdRequired: "Se requiere ID de usuario",
  userNameRequired: "El e-mail es obligatorio",
  planNameRequired: "Se requiere el nombre del plan interno",
  customLetterRequired: "Se requiere la carta personalizada",
  customLetterSavedSuccess: "Carta personalizada guardada con éxito",
  customTagDeleteSuccess: "Etiqueta personalizada eliminada con éxito",
  customTagInsertSuccess: "Etiqueta personalizada creada con éxito",
  customTagNotFound: "Etiqueta personalizada no encontrada",
  customTagUpdateSuccess: "Etiqueta personalizada actualizada con éxito",
  documentStateInsertSuccess: "Estado del documento creado con éxito",
  inquiryLetterRequired: "Se requiere la carta de consulta",
  inquiryLetterSavedSuccess: "Carta de consulta guardada con éxito",
  proposalLetterRequired: "Se requiere la carta de propuesta",
  proposalLetterSavedSuccess: "Carta de propuesta guardada con éxito",
  userProjectRequired: "El proyecto de usuario es obligatorio",
  customDocumentTypeRequired: "El tipo de documento es obligatorio",
  documentAmountRequired: "La cantidad es requerida",
  documentFlaggedRequired: "La fecha es requerida",
  documentStateRequired: "El estado del documento es obligatorio",
  tagDescriptionRequired: "La descripción de la etiqueta es obligatoria",
  tagNameRequired: "El nombre de la etiqueta es obligatorio",
  blockedFundersDeleteAllSuccess: "Todos los financiadores se desbloquearon con éxito",
  blockedFundersDeleteSuccess: "Financiador desbloqueado con éxito",
  blockedFundersInsertSuccess: "Financiador bloqueado con éxito",
  blockedFundersNotFound: "Financiador bloqueado no encontrado",
  customDatabaseDeleteSuccess: "Base de datos personalizada eliminada con éxito",
  customDatabaseFieldsMapRequired: "Por favor. Configure todos los campos obligatorios antes de continuar",
  customDatabaseNotFound: "Base de datos personalizada no encontrada",
  customDatabaseUserFieldsEmpty: "La lista de campos de configuración está vacía",
  favoriteDeleteSuccess: "Favorito eliminado con éxito",
  favoriteDuplicateSuccess: "Favorito duplicado con éxito",
  favoriteFundersDeleteSuccess: "Financiador favorito eliminado con éxito",
  favoriteFundersInsertSuccess: "{{fundersAmount}} financiadores agregados a la lista {{favoriteName}}",
  favoriteFundersNotFound: "Financiador favorito no encontrado",
  favoriteFundersUpdateSuccess: "Actualización de financiador favorito con éxito",
  favoriteInsertSuccess: "Favorito creado con éxito",
  favoriteNotFound: "Favorito no encontrado",
  favoriteUpdateSuccess: "Actualización favorita exitosa",
  fundersListEmpty: "La lista de patrocinadores está vacía",
  noteDeleteSuccess: "Nota eliminada con éxito",
  noteInsertSuccess: "Nota creada con éxito",
  noteNotFound: "Nota no encontrada",
  noteUpdateSuccess: "Nota actualizada correctamente",
  noteRequired: "Se requiere una nota antes de adjuntar cualquier documento",
  notificationDateRequired: "La fecha de notificación es obligatoria",
  emailListRequired: "Seleccione el e-mail",
  customDatabaseFileRequired: "Se requiere el archivo de Excel",
  favoriteNameRequired: "El nombre favorito es obligatorio",
  favoriteRequired: "Se requiere el favorito",
  foundationRequired: "Se requiere la fundación",
  jsonUserFieldsMapRequired: "Por favor. Configure todos los campos obligatorios antes de continuar",
  labelTypeRequired: "El tipo de etiqueta es obligatorio",
  letterTypeRequired: "Seleccione el tipo de documento para imprimir",
  noteDescriptionRequired: "La descripción es obligatoria",
  noteTitleRequired: "El título es obligatorio",
  bundleInsertSuccess: "Paquete creado con éxito",
  bundleNotFound: "Paquete no encontrado",
  bundleTaskCount: "Ingrese al menos una tarea",
  bundleTaskNotFound: "No se pueden encontrar una o más tareas",
  flagDateRequired: "La fecha es requerida",
  flagUpdateSuccess: "Bandera actualizada con éxito",
  projectBudgetCategoryDescriptionRequired: "La descripción de la categoría es obligatoria",
  projectBudgetDeleteSuccess: "Presupuesto eliminado con éxito",
  projectBudgetDescriptionExists: "Esta descripción ya existe",
  projectBudgetInsertSuccess: "Presupuesto añadido con éxito",
  projectBudgetNotFound: "Presupuesto no encontrado",
  projectBudgetResetSuccess: "Presupuesto restablecido con éxito",
  projectBudgetUpdateSuccess: "Presupuesto actualizado correctamente",
  projectEndDateRequired: "La fecha de finalización es obligatoria",
  projectFundersDeleteSuccess: "El financiador del proyecto se eliminó con éxito",
  projectFundersEmpty: "Seleccione al menos un financiador antes de continuar",
  projectFundersInsertSuccess: "{{funderAmount}} financiadores agregados al {{projectName}}",
  projectStartDateRequired: "La fecha de inicio es obligatoria",
  taskDeleteSuccess: "Tarea eliminada con éxito",
  taskEditSuccess: "Tarea actualizada correctamente",
  taskInsertSuccess: "Tarea creada con éxito",
  taskListEmpty: "La lista de tareas está vacía",
  taskNotFound: "Tarea no encontrada",
  userProjectArchivedSuccess: "Proyecto de usuario archivado con éxito",
  userProjectDeleteSuccess: "Proyecto de usuario eliminado con éxito",
  userProjectEndDateRequired: "La fecha de finalización es obligatoria",
  userProjectInsertSuccess: "Proyecto de usuario creado con éxito",
  userProjectListEmpty: "Seleccione uno o más proyectos",
  userProjectNotFound: "Proyecto no encontrado",
  userProjectStartDateInvalid: "La fecha de inicio no es válida",
  userProjectStartDateRequired: "La fecha de inicio es obligatoria",
  userProjectUnarchivedSuccess: "Proyecto de usuario desarchivado con éxito",
  userProjectUpdateSuccess: "Proyecto de usuario actualizado con éxito",
  budgetTypeInvalid: "El tipo de presupuesto no es válido",
  bundleDescriptionRequired: "Se requiere la descripción del paquete",
  bundleNameRequired: "El nombre del paquete es obligatorio",
  bundleTaskRequired: "La lista de tareas es obligatoria",
  flagAmountInvalid: "La cantidad no es válida",
  flagAmountRequired: "La cantidad es requerida",
  flagStatusRequired: "El estado es obligatorio",
  foundationNameRequired: "El nombre de la fundación es obligatorio",
  foundationSourceTypeRequired: "Se requiere la fuente de la fundación",
  profileIdRequired: "El perfil es obligatorio",
  projectBudgetAmountRequired: "El importe del presupuesto es obligatorio",
  projectBudgetDescriptionRequired: "La descripción del presupuesto es obligatoria",
  projectCostInvalid: "El costo del proyecto no es válido",
  projectCostRequired: "Se requiere el costo del proyecto",
  projectDescriptionRequired: "Se requiere la descripción del proyecto",
  projectNameRequired: "El nombre del proyecto es obligatorio",
  projectStatusInvalid: "El estado del proyecto no es válido",
  taskDescriptionRequired: "La descripción de la tarea es obligatoria",
  taskNameRequired: "El nombre de la tarea es obligatorio",
  foundationInsertSuccess: "Fundación añadida con éxito",
  savedSearchDeleteSuccess: "Búsqueda eliminada con éxito",
  savedSearchDescriptionExists: "Esta descripción ya existe",
  savedSearchInsertSuccess: "Búsqueda agregada con éxito",
  savedSearchNotFound: "Búsqueda guardada no encontrada",
  savedSearchTypeInvalid: "El tipo de búsqueda guardada no es válido",
  savedSearchUpdateSuccess: "Búsqueda actualizada con éxito",
  descriptionRequired: "La descripción es obligatoria",
  jsonFieldsRequired: "El campo de búsqueda es obligatorio",
  searchTypeRequired: "El tipo de búsqueda es obligatorio",
  userProjectCompleted: "Este proyecto está marcado como completado",
  sendOnlineApplicationSuccess: "Mensaje de solicitud en línea enviado con éxito",
  sendSuggestionSuccess: "Sugerencia enviada con éxito",
  urlRequired: "La URL es obligatoria",
  noteSubjectRequired: "El tema es obligatorio",
  accountDeleted: "Esta cuenta fue eliminada. Contacta con el administrador",
  userRemovedSuccess: "Cuenta eliminada con éxito",
  userNameOrEmailRequired: "The User or e-mail name is required",
  fieldRequired: "Este campo es obligatorio",
  pickupNewPassword: "Por favor, recoja la nueva contraseña",
  passwordMinCharacter: "Introduce al menos {{minimumCharaters}} caracteres",
  enterSameValueAgain: "Por favor, vuelva a introducir el mismo valor",
  feedbackSubmitted: "Comentarios enviados correctamente",
  feedbackReviewUpdated: "Revisión actualizada de los comentarios",
  announcementDescriptionRequired: "La descripción del anuncio es obligatoria.",
  announcementReleaseDateRequired: "La fecha de publicación del anuncio es obligatoria.",
  announcementReleaseDateInvalid: "Past dates are not allowed for the Release date",
  announcementTypeRequired: "El tipo de anuncio es obligatorio.",
  announcementNotFound: "Anuncio no encontrado",
  announcementInsertSuccess: "Anuncio agregado exitosamente",
  announcementUpdateSuccess: "Anuncio actualizado exitosamente",
  announcementDeleteSuccess: "Anuncio eliminado exitosamente",
  notificationNotFound: "Notification not found",
  couponNameRequired: "El nombre del cupón es obligatorio",
  couponCodeRequired: "El código de cupón es obligatorio",
  couponCodeFormatInvalid: "El código de cupón no es válido",
  codeLengthInvalid: "Máximo de 15 caracteres",
  couponExpirationDateRequired: "Se requiere la fecha de vencimiento del cupón.",
  couponExpirationMaxValue: "La fecha no puede ser mayor a 6 meses a partir de hoy",
  couponExpirationDateInvalid: "Fecha de expiracion inválida",
  couponSubscriptionPlanRequired: "Se requiere el plan de suscripción de cupones",
  couponExtraMonthsAmountInvalid: "El mes extra solo permite del 1 al 99",
  couponDiscountAmountInvalid: "El descuento solo permite del número 1 al 90",
  couponCodeAlreadyExist: "El código del cupón ya ha sido canjeado",
  couponSubscriptionNotFound: "El plan de suscripción no encontrado",
  couponNotFound: "Cupón no encontrado",
  couponSelectType: "Por favor elija entre Tiempo Extra o Descuento",
  couponInsertSuccess: "Cupón agregado exitosamente",
  couponUpdateSuccess: "Cupón actualizado exitosamente",
  couponDeleteSuccess: "Cupón eliminado exitosamente",
  couponPauseUpdateSuccess: "Cupón actualizado exitosamente",
  deadlineEINRequired: "El EIN es obligatorio",
  deadlineFoundationNameRequired: "El nombre de la fundación es obligatorio",
  deadlineCategoryRequired: "La categoría es obligatoria",
  deadlineURLRequired: "Se requiere la URL de la fecha límite",
  deadlineCountryRequired: "El país debe",
  deadlineDateInvalid: "Permitir sólo fechas futuras",
  deadlineAmountToobig: "El importe de la subvención es excesivo",
  deadlineImportSuccess: "{{fileName}} importado con éxito",
  deadlineUpdateSuccess: "Fecha límite actualizada correctamente",
  deadlineDeleteSuccess: "Fecha límite eliminada con éxito",
  thankYouLetterSavedSuccess: "Thank you letter saved with success",
  impactReportLetterSavedSuccess: "Impact report letter saved with success",
  historyTypeRequired: "The history type is required",
  startDateRequired: "La fecha de inicio es obligatoria",
  startDateInvalid: "Seleccione una fecha que sea posterior a la de hoy",
  funderQualifiedSuccess: "Funder qualified successfully",
  funderDisqualifiedSuccess: "Funder disqualified successfully",
};
