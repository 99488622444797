import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    IconButton,
    Tooltip
} from "@mui/material"

import { LoadingButton } from "@mui/lab";
import { Close, HelpOutline } from "@mui/icons-material"

const DialogConfirmWrapper = ({
    openPopup = false,
    onClose,
    titleMessage,
    popupMessage,
    confirmLabel,
    isLoading,
    onConfirm,
    showConfirm = true,
    size = 'xs',
    additionalButtonLabel = null,
    onAdditionalButtonClick = null,
    confirmButtonVisible = true,
    cancelButtonVisible = true,
    cancelLabel = "Cancel",
    onCancelButtonClick = null,
    additionalButtonHelpText = null,
    startIcon = null,
    buttonHelpText = null
}) => {

    return (
            <Dialog open={openPopup} onClose={onClose} fullWidth maxWidth={size}  sx={{ 
                "& .MuiDialog-paper": { 
                    p: 0,
                    borderRadius: 2,
                },
            }}>
                <DialogTitle sx={{ 
                    fontSize: "1rem",
                    bgcolor: "secondary.main",
                    color: "#fff",
                    p: "12px 16px" }}
                >
                     <Typography display="flex" alignItems="center" gap={1}>
                        {startIcon} {titleMessage}
                    </Typography>
                    
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 4,
                        color: '#fff',
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {popupMessage}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mt: 1, mb: 1 }}>

                    <LoadingButton
                        disableElevation
                        size="large"
                        type="button"
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            if (onCancelButtonClick) {
                                onCancelButtonClick();
                            }
                            else {
                                onClose();
                            }
                        }}
                        sx={{visibility: cancelButtonVisible ? "visible" : "hidden"}}
                    >
                        {cancelLabel}
                    </LoadingButton>

                    {showConfirm === true && (
                        <>
                            {additionalButtonLabel !== null && onAdditionalButtonClick !== null && (
                                <LoadingButton
                                    disableElevation
                                    size="large"
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    loading={isLoading}
                                    onClick={onAdditionalButtonClick}
                                    sx={{visibility: confirmButtonVisible ? "visible" : "hidden"}}
                                    {...additionalButtonHelpText && {endIcon: (
                                        <Tooltip title={additionalButtonHelpText}
                                            sx={{ cursor: "pointer" }}>
                                            <HelpOutline fontSize='small' />
                                        </Tooltip>
                                    )}}
                                >
                                    {additionalButtonLabel}
                                </LoadingButton>
                            )}
                            
                            <LoadingButton
                                disableElevation
                                size="large"
                                type="button"
                                variant="contained"
                                color="secondary"
                                loading={isLoading}
                                onClick={onConfirm}
                                sx={{visibility: confirmButtonVisible ? "visible" : "hidden"}}
                                {...buttonHelpText && {endIcon: (
                                    <Tooltip title={buttonHelpText}
                                        sx={{ cursor: "pointer" }}>
                                        <HelpOutline fontSize='small' />
                                    </Tooltip>
                                )}}
                            >
                                {confirmLabel}
                            </LoadingButton>
                        </>
                    )}
                    
                </DialogActions>
            </Dialog>
        )
}

export default DialogConfirmWrapper;