import 'react-image-crop/dist/ReactCrop.css';

import { useDispatch } from 'react-redux'
import { useState, useEffect, useRef } from 'react';

// material-ui
import {
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Alert,
  } from "@mui/material";

import LoadingButton from '@mui/lab/LoadingButton';
import { Close, Upload } from "@mui/icons-material"

// project imports
import emptyImg from 'assets/images/empty_01.png';
import { useTranslation } from "react-i18next";
import { showSuccessToaster, showErrorToaster } from "store/Shared.Slice";
import SubCard from "ui-component/cards/SubCard";
import imgHeaderFooter from "assets/images/header-footer.png"
import imgHeader from "assets/images/header.png"
import { useSaveLetterHeadMutation, useGetLetterHeadMutation } from "Services/LetterHeadService";
import DialogConfirmWrapper from 'components/DialogConfirmWrapper';
import { usePrompt } from 'react-router-dom';


const SharedLetterHeadPage = ({sharedLetterHeadType}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

    const [letterType, setLetterType] = useState('HeaderAndFooter');

    const footerRef = useRef();
    const headerRef = useRef();

    const [saveLetterHead, { isLoading: isSaving }] = useSaveLetterHeadMutation();
    const [getLetterHead, { isLoading }] = useGetLetterHeadMutation()

    const [headerImage, setHeaderImage] = useState({
        previewBlob: null,
        previewUrl: '',
        resizeNotification: false,
        openNotification: false,
        headerImageId: ''
    });
    
    const [footerImage, setFooterImage] = useState({
        previewBlob: null,
        previewUrl: '',
        resizeNotification: false,
        openNotification: false,
        footerImageId: ''
    });

    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

    const [ previousData, setPreviousData ] = useState({
        letterType: 'HeaderAndFooter'
    });

    usePrompt(
        "You have unsaved changes. Are you sure to discard all changes made?",
        headerImage.previewBlob !== null
        || footerImage.previewBlob !== null
        || letterType !== previousData.letterType
    );

    const handleUploadFile = (type, element) => {
        const file = element.target.files[0];

        if (file != null) {

            if (type === 'header') {
                setHeaderImage({
                    ...headerImage,
                    previewBlob: file,
                    previewUrl: URL.createObjectURL(file),
                });

                headerRef.current.value = '';
            }
            else {
                setFooterImage({
                    ...footerImage,
                    previewBlob: file,
                    previewUrl: URL.createObjectURL(file),
                });

                footerRef.current.value = '';
            }
        }
    }

    const handleChangeLetterType = (element) => {
        setLetterType(element.target.value);
    }

    const handleSaveChanges = () => {

        const openConfirm = (
            headerImage.resizeNotification === true ||
            footerImage.resizeNotification === true
        );

        if (openConfirm) {
            setOpenDialogConfirm(true);
        }
        else {
            handleConfirmSave(false);
        }
    }

    const handleConfirmSave = async (autoResize) => {

        try {
            const model = new FormData();

            model.append("headerImage", headerImage.previewBlob);
            model.append("footerImage", footerImage.previewBlob);
            model.append("headerImageId", headerImage.headerImageId);
            model.append("footerImageId", footerImage.footerImageId);
            model.append("letterType", letterType);
            model.append("autoResize", autoResize);

            const response = await saveLetterHead({sharedLetterHeadType, model}).unwrap();

            dispatch(showSuccessToaster(response.message));
            handleGetLetterhead(sharedLetterHeadType);
            setOpenDialogConfirm(false);
        }
        catch(error) {
            // Model Errors
            if (error.data?.errors != null) {
                dispatch(showErrorToaster(Object.values(error.data?.errors)[0][0]));
            }

            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        }

    }

    const handleLoadImage = (event) => {

        const hasNotification = (
            event.target.naturalWidth > event.target.width || 
            event.target.naturalHeight > event.target.height
        );

        if (event.target.id === 'headerImage') {

            if (headerImage.previewBlob !== null) {
                setHeaderImage({
                    ...headerImage,
                    resizeNotification: hasNotification,
                    openNotification: hasNotification
                });
            }
        }

        if (event.target.id === 'footerImage') {

            if (footerImage.previewBlob !== null) {
                setFooterImage({
                    ...footerImage,
                    resizeNotification: hasNotification,
                    openNotification: hasNotification
                });
            }
        }
    }

    const handleGetLetterhead = async() => {

        const response = await getLetterHead(sharedLetterHeadType).unwrap();

        setHeaderImage({
            ...headerImage,
            previewBlob: null,
            previewUrl: response.headerImageUrl,
            openNotification: false,
            headerImageId: response.headerImageId
        });

        setFooterImage({
            ...footerImage,
            previewBlob: null,
            previewUrl: response.footerImageUrl,
            openNotification: false,
            footerImageId: response.footerImageId
        });

        setLetterType(response.letterType);

        setPreviousData({
            ...previousData,
            letterType: response.letterType
        });
    }

    useEffect(() => {
        handleGetLetterhead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <>

        <DialogConfirmWrapper 
            openPopup={openDialogConfirm}
            onClose={() => setOpenDialogConfirm(false)}
            titleMessage="Are you sure?"
            popupMessage={
                <>
                    {headerImage.resizeNotification === true && footerImage.resizeNotification === true && (
                        <Typography>Both images (header and footer) are non-standard images. Automatic resize is recommended</Typography>
                    )}

                    {headerImage.resizeNotification === true && footerImage.resizeNotification === false && (
                        <Typography>The header image is a non-standard image. Automatic resize is recommended</Typography>
                    )}

                    {headerImage.resizeNotification === false && footerImage.resizeNotification === true && (
                        <Typography>The footer image is a non-standard image. Automatic resize is recommended</Typography>
                    )}
                </>
            }
            confirmLabel="Confirm Resize"
            isLoading={isSaving}
            onConfirm={() => handleConfirmSave(true)}
            additionalButtonLabel="Confirm original"
            onAdditionalButtonClick={() => handleConfirmSave(false)}
            size="sm"
        />

        {/*<SharedImageCropPopup 
            isOpen={openDialog}
            onClose={() => setOpenDialog(false)}
            image={uploadType === 'header' ? headerImage.image : footerImage.image}
            onConfirm={handlePopupConfirm}
        />*/}

        <Grid container spacing={matchDownSM ? 0 : 2}>
            <Grid item xs={12} sm={12} md={12} pb={2}>
                <Typography variant="h4">{t('labels.add_digital_letterhead')}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} mb={-2}>
                <Typography variant="caption">
                    {t('messages.first_letterhead_message')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} mb={-2}>
                <Typography variant="caption">
                    {t('messages.second_letterhead_message')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} mb={2}>
                <Typography variant="caption">
                    {t('messages.third_letterhead_message')}
                </Typography>
            </Grid>

            <Grid item xs={8} sm={8} md={8}>
                
                <Grid item xs={12} sm={12} md={12} mb={4}>
                    <SubCard title={
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <label htmlFor="upload-header">
                                    <input ref={headerRef} accept="image/*" id="upload-header" type="file" style={{ display: "none" }} onChange={(e) => handleUploadFile('header', e)} />
                                    <Button
                                        disableElevation
                                        size="large"
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        component="span"
                                        startIcon={<Upload />}
                                    >
                                        {t('buttons.select_header_file')}
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item>
                                <Button
                                    disableElevation
                                    size="large"
                                    type="button"
                                    variant="text"
                                    color="secondary"
                                    component="span"
                                    startIcon={<Close />}
                                    onClick={() => {
                                        setHeaderImage({
                                            ...headerImage,
                                            previewBlob: null,
                                            previewUrl: '',
                                            resizeNotification: false,
                                            openNotification: false,
                                            headerImageId: ''
                                        })
                                    }}
                                >
                                    Clear Image
                                </Button>
                            </Grid>
                        </Grid>
                        
                    } >
                        <Grid container>

                            {headerImage.openNotification && (
                                <Grid item xs={12} mb={2}>
                                    <Alert severity="warning" onClose={() => setHeaderImage({...headerImage, openNotification: false })} variant='filled'>
                                        Non-standard image. Recommended: 700 x 200
                                    </Alert>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={12} md={12} alignItems="center" justifyContent="center" display="flex">
                                <img 
                                    id="headerImage"
                                    src={headerImage.previewUrl === '' ? emptyImg : headerImage.previewUrl} 
                                    alt="" 
                                    style={{maxWidth: '700px', maxHeight: '200px', width: 'auto', height: 'auto'}} 
                                    onError={() => { setHeaderImage({
                                        ...headerImage,
                                        previewBlob: null,
                                        previewUrl: emptyImg
                                    })}}
                                    onLoad={handleLoadImage}
                                />
                            </Grid>
                        </Grid>

                    </SubCard>
                </Grid>

                <div hidden={letterType !== 'HeaderAndFooter'}>
                    <Grid item xs={12} sm={12} md={12}>
                        <SubCard title={
                            <Grid container justifyContent="space-between">

                                <Grid item>
                                    <label htmlFor="upload-footer">
                                        <input ref={footerRef} accept="image/*" id="upload-footer" multiple type="file" style={{ display: "none" }} onChange={(e) => handleUploadFile('footer', e)} />
                                        <LoadingButton
                                            disableElevation
                                            size="large"
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            component="span"
                                            startIcon={<Upload />}
                                        >
                                            {t('buttons.select_footer_file')}
                                        </LoadingButton>
                                    </label>
                                </Grid>

                                <Grid item>
                                    <Button
                                        disableElevation
                                        size="large"
                                        type="button"
                                        variant="text"
                                        color="secondary"
                                        component="span"
                                        startIcon={<Close />}
                                        onClick={() => {
                                            setFooterImage({
                                                ...footerImage,
                                                previewBlob: null,
                                                previewUrl: '',
                                                resizeNotification: false,
                                                openNotification: false,
                                                footerImageId: ''
                                            })
                                        }}
                                    >
                                        Clear Image
                                    </Button>
                                </Grid>

                            </Grid>
                           
                        }>
                            <Grid container>

                                {footerImage.openNotification && (
                                    <Grid item xs={12} mb={2}>
                                        <Alert severity="warning" onClose={() => setFooterImage({...footerImage, openNotification: false })} variant='filled'>
                                            Non-standard image. Recommended: 700 x 200
                                        </Alert>
                                    </Grid>
                                )}

                                <Grid item xs={12} sm={12} md={12} alignItems="center" justifyContent="center" display="flex">
                                    <img
                                        id="footerImage" 
                                        src={footerImage.previewUrl === '' ? emptyImg : footerImage.previewUrl} 
                                        alt="" 
                                        style={{maxWidth: '700px', maxHeight: '200px', width: 'auto', height: 'auto'}} 
                                        onError={() => { setFooterImage({
                                            ...footerImage,
                                            previewBlob: null,
                                            footerImageId: null,
                                            previewUrl: emptyImg
                                        })}}
                                        onLoad={handleLoadImage}
                                    />
                                </Grid>
                            </Grid>

                        </SubCard>
                    </Grid>

                </div>

            </Grid>

            <Grid item xs={4} sm={4} md={4}>
                <SubCard title={`${t('labels.select_header_style')}`}>

                    <RadioGroup name="radio1" 
                        defaultValue="HeaderAndFooter"
                        value={letterType}
                        onChange={handleChangeLetterType}
                    >

                        <Grid container alignItems="center" mb={2}>
                            <Grid item  xs={6} sm={6} md={6}>
                                <FormControlLabel value="HeaderOnly" control={<Radio />} label={`${t('labels.header_only')}`} />
                            </Grid>
                            <Grid item  xs={6} sm={6} md={6} style={{ display: 'flex' }} justifyContent="end" >
                                <img alt="" src={imgHeader} />
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center">
                            <Grid item  xs={6} sm={6} md={6}>
                                <FormControlLabel value="HeaderAndFooter" control={<Radio />} label={`${t('labels.header_and_footer')}`} />
                            </Grid>
                            <Grid item  xs={6} sm={6} md={6} style={{ display: 'flex' }} justifyContent="end">
                                <img alt="" src={imgHeaderFooter} />
                            </Grid>
                        </Grid>

                    </RadioGroup>

                </SubCard>

            </Grid>

            <Grid item>
                <LoadingButton
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{mt: 2}}
                    loading={ isSaving || isLoading }
                    onClick={handleSaveChanges}
                >
                    {t('buttons.save_changes')}
                </LoadingButton>
            </Grid>

        </Grid>

        </>
    );

}

export default SharedLetterHeadPage;