// assets
import { IconUsers, IconBusinessplan } from '@tabler/icons';

// constant
export const icons = {
    IconUsers,
    IconBusinessplan
};

const administration = {
    id: 'administration',
    title: 'side_menu.administration',
    type: 'group',
    roles: ['admin', 'superadmin', 'it', 'sales'],
    collapse: false,
    children: [
        {
            id: 'administration-manage-account',
            title: 'side_menu.manage_account',
            type: 'item',
            url: 'main/admin/manage-accounts',
            icon: 'IconUsers', //icons.IconUsers,
            breadcrumbs: false,
            roles: ['admin', 'superadmin', 'it'],
        },
        {
            id: 'administration-manage-announcement',
            title: 'side_menu.manage_announcement',
            type: 'item',
            url: 'main/admin/manage-announcements',
            icon: 'IconUsers', //icons.IconUsers,
            breadcrumbs: false,
            roles: ['admin', 'superadmin', 'it'],
        },
        {
            id: 'administration-manage-subscription-plan',
            title: 'side_menu.manage_subscription_plan',
            type: 'item',
            url: 'main/admin/manage-subscription-plans',
            icon: 'IconUsers', //icons.IconUsers,
            breadcrumbs: false,
            roles: ['admin', 'superadmin', 'it'],
        },
        {
            id: 'administration-manage-coupon',
            title: 'side_menu.manage_coupons',
            type: 'item',
            url: 'main/admin/manage-coupons',
            icon: 'IconUsers', //icons.IconUsers,
            breadcrumbs: false,
            roles: ['admin', 'superadmin', 'it', 'sales'],
        },
        {
            id: 'administration-manage-feedback',
            title: 'side_menu.manage_feedbacks',
            type: 'item',
            url: 'main/admin/manage-feedbacks',
            icon: 'IconUsers', //icons.IconUsers,
            breadcrumbs: false,
            roles: ['admin', 'superadmin'],
        },
        {
            id: 'administration-manage-deadline',
            title: 'side_menu.manage_deadlines',
            type: 'item',
            url: 'main/admin/manage-deadlines',
            icon: 'IconUsers', //icons.IconUsers,
            breadcrumbs: false,
            roles: ['admin', 'superadmin'],
        },
        /*{
            id: 'administration-manage-plans',
            title: 'side_menu.manage_plans',
            type: 'item',
            url: 'main/admin/manage-plans',
            icon: 'IconBusinessplan', //icons.IconBusinessplan,
            breadcrumbs: false,
            roles: ['admin', 'superadmin', 'it'],
        }*/
    ]
};

export default administration;
