import { useRef, useEffect } from "react";
import {
    useTheme,
    Box, 
    Stack,
    Typography,
    IconButton,
    Chip,
    Divider,
    LinearProgress,
} from "@mui/material";
import { Download } from "@mui/icons-material";

import SubCard from "ui-component/cards/SubCard";
import { grey } from "@mui/material/colors";

const NotificationCard = ({
    title,
    fullDescription,
    tagNew,
    creationTimeAgo,
    documents=[],
    isLoading,
}) => {

    const theme = useTheme();
    const descriptionRef = useRef();

    useEffect(() => {
        const images = descriptionRef.current.querySelectorAll("img");
        images.forEach(image => {

            image.style.border = "1px solid #fff";
            image.style.borderRadius = "8px";
            image.style.boxShadow = "0px 2px 2px 1px rgba(0, 0, 0, 0.1), 0px 2px 8px 3px rgba(0, 0, 0, 0.1)";

            const parent = image.parentElement;
            parent.style.display = "flex";
            parent.style.justifyContent = "center";
            parent.style.gap = "4px"
        })
    }, []);

    return (
        <SubCard sx={{ mb: 2, ".MuiCardContent-root": { p: 2 } }}>
            <Box display="flex" gap={1} alignItems="center" mb={2}>
                {tagNew &&
                    <Chip 
                        sx={{
                            height: 24,
                            p: '0 6px',
                            color: theme.palette.warning.dark,
                            backgroundColor: theme.palette.warning.light
                        }}
                        label="NEW"
                    />
                }
                <Typography variant="caption" sx={{ color: theme.palette.mode === "light" ? "#a7b1c4" : theme.palette.grey[500] }}>
                    {creationTimeAgo}
                </Typography>
            </Box>
            <Typography variant="h3" sx={{ color: theme.palette.mode === "light" ? grey[800] : "#fff", mb: 3 }}>{title}</Typography>
            <Typography 
                ref={descriptionRef}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: fullDescription ?? "" }}
                sx={{ 
                    'p>br': { display: 'none' },
                    'p:not(:last-of-type)': { marginBottom: '1.5rem' },
                }}  
            />
            
            {(isLoading || documents.length > 0) && (
                <Box sx={{ 
                    bgcolor: "grey.100", 
                    p: 1.5, 
                    borderRadius: 2, 
                    border: "1px solid", 
                    borderColor: "grey.300",
                    mt: 4,
                }}>
                    {isLoading 
                        ? <Stack spacing={1}>
                                <Typography variant="h6" color="grey.500">
                                    Loading Attached Documents
                                </Typography>
                            <LinearProgress color="primary" />
                        </Stack>
                    : (documents.length > 0 &&
                        <Stack>
                            <Typography variant="h6" fontWeight="bold" color="grey.500" pb={2}>
                                Attached Documents
                            </Typography>
                            {documents.map((document, i) => (
                                <div key={document.name}>
                                    {i <= documents.length - 1 && <Divider sx={{ borderColor: "grey.300" }} />}
                                    <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6" color="grey.500">{document.name}</Typography>
                                        <IconButton color="secondary" onClick={() => window.open(document.url)}>
                                            <Download />
                                        </IconButton>
                                    </Stack>
                                </div>
                            ))}
                        </Stack>)
                    }
                </Box>
            )}
        </SubCard>
    )
}

export default NotificationCard;