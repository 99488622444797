import { createSlice } from "@reduxjs/toolkit";
import { subscriptionPlanApi } from 'Services/SubscriptionPlanService';


export const initialState = {
    roleList: ['Admin', 'User', 'SubUser', 'supervisor', 'member', 'Demo', 'Sales', 'SuperAdmin', 'IT'],
    subscriptionPlanName: {
        value: '',
        error: ''
    },
    planDescription: {
        value: '',
        error: ''
    },
    displayplan: {
        value: '',
        error: ''
    },
    amountYears: {
        value: '',
        error: ''
    },
    amountMonths: {
        value: '',
        error: ''
    },
    amountDays: {
        value: '',
        error: ''
    },
    amountLicenses: {
        value: '',
        error: ''
    }
};

const updateSubscriptionPlanSlice = createSlice({
    name: "update-subscription-plan",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state[action.payload.field].error = '';
            state[action.payload.field].value = action.payload.value;            
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        handleChangeSubscriptionPlan: (state, action) => {
            state.subscriptionPlanName.value = action.payload;
            state.subscriptionPlanName.error = '';
            
        },   
        handleChangeDisplayPlan: (state, action) => {
            state.displayplan = action.payload;
        }, 
        handleChangeProcessPayment: (state, action) => {
            state.processPayment = action.payload;
        },    
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            subscriptionPlanApi.endpoints.getSubscriptionPlan.matchFulfilled, (state, action) => {
                Object.entries(action.payload).forEach(([field, value]) => {
                    if (state[field] != null) {

                    }
                });                 
            }
        )
    }
});

export const { 
    handleChange,
    handleModelErrors,
    handleChangeSubscriptionPlan,
    handleChangeProcessPayment,
    handleChangeDisplayPlan
} = updateSubscriptionPlanSlice.actions;

export default updateSubscriptionPlanSlice.reducer;