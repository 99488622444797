import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    message: {
        text: "",
        title: "",
        isOpen: false,
    },
    toaster: {
        message: "",
        severity: "error",
        isOpen: false,
        format: "",
        withTransition: true
    },
    countryList: [
        { key: "UnitedStates", value: "United States of America" },
        { key: "Canada", value: "Canada" },
    ],
    token: null,
    userToken: null,
    menuItem: {
        items: []
    },
    documentGenerator: {
        projectFundersVisibility: {
            inquiry: false,
            proposal: false,
            thankyou: false,
            impactReport: false,
            customLetter: false,
        }
    },
};

const sharedSlice = createSlice({
    name: "shared",
    initialState,
    reducers: {
        showSuccessToaster: (state, action) => {
            state.toaster.message = action.payload;
            state.toaster.isOpen = true;
            state.toaster.severity = "success";
            state.toaster.format = "";
            state.toaster.withTransition = true;
        },
        showSuccessToasterWithoutTransitions: (state, action) => {
            state.toaster.message = action.payload;
            state.toaster.isOpen = true;
            state.toaster.severity = "success";
            state.toaster.format = "";
            state.toaster.withTransition = false;
        },
        showSuccessWithFormat: (state, action) => {
            state.toaster.message = action.payload.message;
            state.toaster.isOpen = true;
            state.toaster.severity = "success";
            state.toaster.format = action.payload.format;
            state.toaster.withTransition = true;
        },
        showErrorToaster: (state, action) => {
            state.toaster.message = action.payload;
            state.toaster.isOpen = true;
            state.toaster.severity = "error";
            state.toaster.format = "";
            state.toaster.withTransition = true;
        },
        closeToaster: (state, action) => {
            state.toaster.message = "";
            state.toaster.isOpen = false;
            state.toaster.format = "";
            state.toaster.withTransition = true;
        },
        showMessage: (state, action) => {
            state.message.text = action.payload;
            state.message.title = "Error";
            state.message.isOpen = true;
        },
        showSessionTimeout: (state, action) => {
            state.toaster.message = 'Session Timeout';
            state.toaster.isOpen = true;
            state.toaster.severity = "error";
            state.toaster.format = "";
            state.toaster.withTransition = true;
        },
        closeMessage: (state, action) => {
            state.message.message = "";
            state.message.isOpen = false;
        },
        setUserToken: (state, action) => {
            state.userToken = action.payload;
        },
        loadMenuItems: (state, action) => {
            state.menuItem = { ...action.payload };
        },
        collapseMenu: (state, action) => {
            const itemsCopy = [...state.menuItem.items].map(copy => ({
                ...copy,
                collapse: (copy.id === action.payload) ? !copy.collapse : false
            }));
            state.menuItem.items = itemsCopy;
        },
        collapseAll: (state, action) => {
            const itemsCopy = [...state.menuItem.items].map(copy => ({
                ...copy,
                collapse: false
            }));
            state.menuItem.items = itemsCopy;
        },
        setProjectFundersVisibility: (state, action) => {
            const { key, value } = action.payload;
            state.documentGenerator.projectFundersVisibility[key] = value;
        },
    },
});

export const {
    showSuccessToaster,
    showErrorToaster,
    closeToaster,
    showMessage,
    closeMessage,
    setUserToken,
    showSuccessWithFormat,
    loadMenuItems,
    collapseMenu,
    collapseAll,
    showSessionTimeout,
    setProjectFundersVisibility,
    showSuccessToasterWithoutTransitions
} = sharedSlice.actions;

export default sharedSlice.reducer;
