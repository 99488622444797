import {
    Drawer, 
    Box, 
    Stack,
    Typography, 
    Toolbar,
    IconButton,
    Skeleton,
} from "@mui/material";
import { Notifications, Close } from "@mui/icons-material";

import { grey } from "@mui/material/colors";
import ScrollBar from "react-perfect-scrollbar";
import NotificationCard from "ui-component/cards/NotificationCard";
import NoNotification from "assets/images/icons/notification-cute.svg";

const LoadingNotificationSkeleton = () => (
  <Box sx={{ width: 470, p: 1.5, overflow: "hidden" }}>
    {Array(10)
      .fill(0)
      .map((_, i) => (
        <div key={i}>
          <Skeleton width="70%" />
          <Skeleton height={300} variant="rectuangular" sx={{ mb: 2 }} />
        </div>
      ))}
  </Box>
);

const NoNotificationComponent = () => (
  <Stack mt={4} justifyContent="center" alignItems="center" spacing={4}>
    <img alt="No Notification" src={NoNotification} width="150px" />
    <Typography
      variant="h4"
      sx={{
        color: (theme) => (theme.palette.mode === "light" ? grey[800] : "#fff"),
        mb: 3,
      }}
    >
      No notifications
    </Typography>
  </Stack>
);

const NotificationDrawer = ({
    open,
    onClose,
    isLoading,
    notifications = [],
}) => {

    return (
        <Drawer open={open} onClose={onClose} anchor="right" sx={{
            position: "relative",
            "& .MuiDrawer-paper": { 
                height: "100vh !important"
            }
        }}>
            <Toolbar
                disableGutters
                sx={{
                    justifyContent: "space-between",
                    p: 1.5,
                    borderBottom: "1px solid",
                    borderColor: "grey.200",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    bgcolor: "secondary.main"
                }}
            >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Notifications color="secondary" fontSize="small" sx={{ color: "#fff" }} />
                    <Typography variant="h4" color="#fff">Notifications</Typography>
                </Stack>
                <IconButton onClick={onClose} sx={{ color: "#fff" }}>
                    <Close />
                </IconButton>
            </Toolbar>

            {isLoading 
                ? <LoadingNotificationSkeleton />
                : (<ScrollBar>
                        <Box sx={{ width: 470, p: 1.5 }}>
                            {notifications.length > 0 ?
                                notifications.map(notification => (
                                    <NotificationCard
                                        key={notification.info.notificationId}
                                        title={notification.info.title}
                                        fullDescription={notification.info.fullDescription}
                                        tagNew={notification.info.tagNew}
                                        creationTimeAgo={notification.info.creationTimeAgo}
                                        documents={notification.documents} 
                                        isLoading={notification.isDocumentsLoading} 
                                    />
                                ))
                            : <NoNotificationComponent />
                        }
                        </Box>
                    </ScrollBar>)
            }
        </Drawer>
    )
}

export default NotificationDrawer;