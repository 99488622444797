import { useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    ButtonBase,
} from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

import { IconBell } from '@tabler/icons';
import { 
    useGetUserNotificationsFullReadListMutation,
    useGetAmountOfNotificationsMutation,
    useGetNotificationDocumentsMutation,
} from 'Services/UserNotificationService';

import { useDispatch } from 'react-redux';
import { showErrorToaster } from 'store/Shared.Slice';

import NotificationDrawer from './NotificationDrawer';

const NotificationSectionV2 = ({ welcomeClosed = false }) => {

    const theme = useTheme();
    const dispatch = useDispatch();

    const [getUserNotificationList, { isLoading: isNotificationListLoading }] = useGetUserNotificationsFullReadListMutation();
    const [ getAmountOfNotifications ] = useGetAmountOfNotificationsMutation();
    const [getAnnouncementDocument] = useGetNotificationDocumentsMutation();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isNotificationFetched, setIsNotificationFetched] = useState(false);
    const [userNotifications, setUserNotifications] = useState([]);
    const [notificationsAmount, setNotificationsAmount] = useState({
        allNotifications: 0,
        unreadNotifications: 0
    });

    const handleGetAmountOfNotifications = () => {
        getAmountOfNotifications().unwrap().then(response => {
            setNotificationsAmount({
                ...notificationsAmount,
                allNotifications: response?.data?.allNotifications,
                unreadNotifications: response?.data?.unreadNotifications,
            });
        }, error => {
            // Bad Request
            if (error.data?.message != null) {
                dispatch(showErrorToaster(error.data?.message));
            }
        });
    }

    const getNotificationDocuments = (announcementId) => {
        getAnnouncementDocument(announcementId).unwrap().then(resp => {
            setUserNotifications(prevList => prevList.map
                (notification => 
                    notification.info.announcementId === announcementId 
                    ? { ...notification, documents: resp, isDocumentsLoading: false } 
                    : notification
                ));
        });
    }

    const handleOpenDrawer = () => {
        setDrawerOpen(true);

        if(!isNotificationFetched) {
            getUserNotificationList().unwrap().then(resp => {

                const userNotificationsTemp = [];

                resp.forEach(notification => {
                    userNotificationsTemp.push({
                        info: notification,
                        documents: [],
                        isDocumentsLoading: true,
                    })

                    getNotificationDocuments(notification.announcementId);
                })

                setUserNotifications(userNotificationsTemp);
                setIsNotificationFetched(true);

                setNotificationsAmount({
                    ...notificationsAmount,
                    unreadNotifications: resp.filter(o => o.tagUnread === true).length,
                });  
            });
        }
    }

    useEffect(() => {
        handleGetAmountOfNotifications();
    }, []);

    useEffect(() => {
        if (welcomeClosed) {
            handleOpenDrawer();
        }
    }, [welcomeClosed]);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{borderRadius: '12px'}}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light :theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.dark :theme.palette.secondary.light
                            }
                        }}
                        color="inherit"
                        onClick={handleOpenDrawer}
                    >
                    
                        {(notificationsAmount.unreadNotifications === 0) && (
                            <IconBell 
                                sx={{
                                    color: theme.palette.mode === 'dark' ? 'secondary.dark' : 'secondary.main!important',
                                    background: theme.palette.mode === 'dark' ? 'secondary.dark' : 'secondary.main!important'
                                }}
                                stroke={1.5} size="20" />
                        )}

                        {(notificationsAmount.unreadNotifications > 0 ) && (
                            <Badge color="info"  overlap="circular" badgeContent=" " variant="dot">
                                <AnimateButton type="jingle">
                                    <IconBell sx={{
                                                color: theme.palette.mode === 'dark' ? 'secondary.dark' : 'secondary.light'
                                        }}  stroke={1.5} size="20" />
                                </AnimateButton>
                                
                            </Badge>  
                        )}
                    </Avatar>
                </ButtonBase>
            </Box>

            <NotificationDrawer 
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                notifications={userNotifications}
                isLoading={isNotificationListLoading}
            />
        </>
    )
}

export default NotificationSectionV2;