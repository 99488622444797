import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    foundationName: {
        value: '',
        error: ''
    },
    registeredName: {
        value: '',
        error: ''
    },
    otherName: {
        value: '',
        error: ''
    },
    phoneNumber: {
        value: '',
        error: ''
    },
    email: {
        value: '',
        error: ''
    },
    site: {
        value: '',
        error: ''
    },
    address: {
        value: '',
        error: ''
    },
    complement: {
        value: '',
        error: ''
    },
    city: {
        value: '',
        error: ''
    },
    state: {
        value: '',
        error: ''
    },
    zipCode: {
        value: '',
        error: ''
    },
    ein: {
        value: '',
        error: ''
    }   
};

const updateFoundationSlice = createSlice({
    name: "update-foundation-us",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state[action.payload.field].error = '';
            state[action.payload.field].value = action.payload.value;            
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        
    },
    
});

export const { 
    handleChange,
    handleModelErrors,
} = updateFoundationSlice.actions;

export default updateFoundationSlice.reducer;