import { emptySplitApi } from './index'

export const searchEngineApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        searchBySearchType: builder.mutation({
            query: ({searchType, ...model}) => ({
                url: `api/v1/search-engine-service/search/${searchType}`,
                method: "POST",
                body: model
            }),
        }),
        searchByGivingHistory: builder.mutation({
            query: ({searchType, ...model}) => ({
                url: `api/v1/search-engine-service/search/by-giving-history`,
                method: "POST",
                body: model
            }),
        }),
        searchByGivingHistoryV2: builder.mutation({
            query: ({searchType, ...model}) => ({
                url: `api/v2/search-engine-service/search/by-giving-history`,
                method: "POST",
                body: model
            }),
        }),
        getGrantDetails: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/search/grant-details/${model.profileId}`,
                method: "POST",
                body: model
            }),
        }),
        getGrantDetailsV2: builder.mutation({
            query: (model) => ({
                url: `api/v2/search-engine-service/search/grant-details/${model.profileId}`,
                method: "POST",
                body: model
            }),
        }),
        searchByName: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/search/by-name`,
                method: "POST",
                body: model
            }),
        }),
        searchByNameV2: builder.mutation({
            query: (model) => ({
                url: `api/v2/search-engine-service/search/by-name`,
                method: "POST",
                body: model
            }),
        }),
        searchByDirector: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/search/by-director`,
                method: "POST",
                body: model
            }),
        }),
        searchByDirectorV2: builder.mutation({
            query: (model) => ({
                url: `api/v2/search-engine-service/search/by-director`,
                method: "POST",
                body: model
            }),
        }),
        searchByYear: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/search/by-year`,
                method: "POST",
                body: model
            }),
        }),
        searchByRating: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/search/by-rating`,
                method: "POST",
                body: model
            }),
        }),
        searchByCustom: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/search/by-custom`,
                method: "POST",
                body: model
            }),
        }),
        searchByKeyword: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/search/by-keywords`,
                method: "POST",
                body: model
            }),
        }),
        searchByKeywordV2: builder.mutation({
            query: (model) => ({
                url: `api/v2/search-engine-service/search/by-keywords`,
                method: "POST",
                body: model
            }),
        }),
        getCountries: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/country`,
                method: "GET",
            }),
        }),
        getInternacionalCountries: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/country/internacional`,
                method: "GET",
            }),
        }),
        getCounties: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/country/counties`,
                method: "POST",
                body: model
            }),
        }),
        getCitiesByCounties: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/country/cities-by-counties`,
                method: "POST",
                body: model
            }),
        }),
        searchByInternacionalFunding: builder.mutation({
            query: (model) => ({
                url: `api/v1/search-engine-service/search/by-internacional-funding`,
                method: "POST",
                body: model
            }),
        }),
        searchByInternacionalFundingV2: builder.mutation({
            query: (model) => ({
                url: `api/v2/search-engine-service/search/by-internacional-funding`,
                method: "POST",
                body: model
            }),
        }),
        getEnhancedWordsV2: builder.mutation({
            query: (model) => ({
                url: `api/v2/search-engine-service/search/enhancement-words`,
                method: "POST",
                body: model
            }),
        }),
    }),
    overrideExisting: false,
});

export const { 
    useSearchBySearchTypeMutation,
    useSearchByGivingHistoryMutation,
    useSearchByGivingHistoryV2Mutation,
    useGetGrantDetailsMutation,
    useGetGrantDetailsV2Mutation,
    useSearchByNameMutation,
    useSearchByDirectorMutation,
    useSearchByYearMutation,
    useSearchByRatingMutation,
    useSearchByCustomMutation,
    useSearchByKeywordMutation,
    useGetCountriesMutation,
    useGetCountiesMutation,
    useGetCitiesByCountiesMutation,
    useGetInternacionalCountriesMutation,
    useSearchByInternacionalFundingMutation,
    useSearchByInternacionalFundingV2Mutation,
    useGetEnhancedWordsV2Mutation,
    useSearchByNameV2Mutation,
    useSearchByDirectorV2Mutation,
    useSearchByKeywordV2Mutation
} = searchEngineApi;
