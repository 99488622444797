import { createSlice } from "@reduxjs/toolkit";
import { foundationProfileCanadianApi } from "Services/FoundationProfileCanadianService";
import { notesApi } from "Services/NoteService";
import { countryApi } from "Services/CountryService";
import { sectorCAApi } from "Services/SectorServiceCA";
import { formatPhoneNumber } from "utils/formatPhoneNumber";

export const initialState = {
    openPopupNote: false,
    noteData: {
        id: null,
        title: '',
        description: '',
        notificationDate: null,
        emailNotification: false,
        emails:[],
    },
    loadingGifts: false,
    applyFilter: '',
    init: false,
    profileName: '',
    lastReportYear: null,
    registerYear: null,
    fiscalPeriodEnd: '',
    stateOrProvince: '',
    organizationRegion: '',
    city: '',
    onlineApplication: '',
    website: '',
    phoneNumber: '',
    fullAddress: '',
    nteeTitle: '',
    nteeDescription: '',
    recipientName: '',
    countryList: [],
    provinceOrStateList: [],
    selectedFilters: {
        country: [],
        provinceOrState: [],
        county: [],
        city: [],
        years: [],
        sectors: [],
    },
    cityList: [],
    sectorList: [],
    countyList: [],
    stemmingSearch: true,
    contactEmail: '',
    donorDesignation: '',
    nationalGivingHistory:'',
    applicationData: {
        foundationId: null
    },
    programData: {
        foundationId: null
    },
    yearList: [
        ...Array(new Date().getFullYear() -1 - 2014 + 1)
        .fill()
        .map((_, idx) => ({
            index: idx,
            id:  (2014 + idx).toString(),
            value: (2014 + idx).toString(),
            label: (2014 + idx).toString(),
            checked: false
        }))
    ],
    summary: {
        avgGrantSize: null,
        totalAsserts: null,
        avgGrantsAnnually: null,
        grantsState: null,
        avgAmount: null,
        largestAmount: null,
        medianAmount: null,
        range: null,
        customAnalysisList: [],
        foundationFinancialList: [],
        yearAnalysisList: [],
        nationalGivingHistory: null
    },
    giftList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'donationdate',
        orderDirection: 'desc',
        items: [],
        statesFilter: [],
    },
    grantOverviewList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'category',
        orderDirection: 'desc',
        items: [
            { category: 'Animal Welfare', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Art & Culture', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Community', grants: '50', largest: '$200,00', average: '$2,000', score: '3', grantamount: '$300,00' },
            { category: 'Education', grants: '333', largest: '$1,317,386,921', average: '$3,958,476', score: '5', grantamount: '$1,318,172,382' },
            { category: 'Environment', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Health', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Religion', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Social & Human Services', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'Sports & Recreation', grants: '0', largest: '$0', average: '$0', score: '1', grantamount: '$0' },
            { category: 'TOTAL', grants: '383', largest: '-', average: '-', score: '-', grantamount: '$1,318,472,382' },   
        ]
    },
    directorList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'directorname',
        orderDirection: 'desc',
        items: []
    },
    noteList: {
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        orderField: 'createdAt',
        orderDirection: 'desc',
        items: []
    },
    charts: {
        provinceList: [],
        rangeAmount: [],
        rangeSector: {}
    },
    toggleExpandFilters: {
        provinceOrState: false,
        city: false,
        year: false,
        stemmingSearch: false,
        county: false,
        sector: false
    },
    taxReturnPdf: []
    
};

const foundationProfileCASliceV2 = createSlice({
    name: "foundation-profile-ca-v2",
    initialState,
    reducers: {
        init: (state, action) => {
            state.init = true;
        },
        handleChangeFilter: (state, action) => {
            const { key, value } = action.payload;
            state.selectedFilters[key] = value;
        },
        handleChangeRecipientName: (state, action) => {
            state.recipientName = action.payload;
        },
        handleAddNewNote: (state, action) => {
            state.openPopupNote = true;
            state.noteData = {...initialState.noteData};
        },
        handleEditNote: (state, action) => {
            state.openPopupNote = true;
            state.noteData.id = action.payload.id;
            state.noteData.title = action.payload.title;
            state.noteData.description = action.payload.description;
            state.noteData.notificationDate = action.payload.notificationDate;
            state.noteData.emailNotification = action.payload.emailNotification;
            state.noteData.emails = action.payload.emails;
        },
        handleClosePopupNote: (state, action) => {
            state.openPopupNote = false;
            state.noteData = {...initialState.noteData};
        },
        handleChangeStemmingSearch: (state, action) => {
            state.stemmingSearch = action.payload;
        },
        handleClear: (state, action) => {
            state.openPopupNote = false;
            state.noteData = { ...initialState.noteData };
            state.profileName = '';
            state.lastReportYear = null;
            state.lastReportYear = null;
            state.registerYear = null;
            state.fiscalPeriodEnd = '';
            state.stateOrProvince = '';
            state.organizationRegion = '';
            state.city = '';
            state.onlineApplication = '';
            state.website = '';
            state.phoneNumber = '';
            state.fullAddress = '';
            state.recipientName = '';
            state.stemmingSearch = true;
            state.nationalGivingHistory = '';
            state.yearList = [
                ...Array(new Date().getFullYear() -1 - 2014 + 1)
                .fill()
                .map((_, idx) => ({
                    index: idx,
                    id:  (2014 + idx).toString(),
                    value: (2014 + idx).toString(),
                    label: (2014 + idx).toString(),
                    checked: false
                }))
            ];
            state.summary = { ...initialState.summary };
            state.charts = { ...initialState.charts };
            state.giftList = { ...initialState.giftList };
            state.countryList = [];
            state.provinceOrStateList = [];
            state.cityList = [];
            state.countyList = [];
            state.sectorList = [];
            state.stemmingSearch = true;            
            
        },
        handleApplyFilter: (state, action) => {
            state.applyFilter = action.payload;
        },
        handleClearCityList: (state, action) => {
            state.cityList = [];
        },
        handleClearGifts: (state, action) => {

            state.selectedFilters.provinceOrState = [];
            state.selectedFilters.county = [];
            state.selectedFilters.city = [];
            state.selectedFilters.years = [];
            state.selectedFilters.sectors = [];

            state.cityList = [];

            state.applyFilter = action.payload;
            state.stemmingSearch = true;
            state.toggleExpandFilters = { ...initialState.toggleExpandFilters };
            
            state.giftList.pageNumber = 0;
            state.giftList.pageSize = 10;
            state.giftList.orderField = 'donationdate';
            state.giftList.orderDirection = 'desc';
        },
        handleToggleExpandFilters: (state, action) => {
            state.toggleExpandFilters = {
                ...state.toggleExpandFilters,
                [action.payload.id]: action.payload.value 
            }
        },
        handleUpdateNoteItem: (state, action) => {
            var noteItem = state.noteList.items?.find(o => o.id === action.payload.id);
            if(noteItem) {
                noteItem[action.payload.key] = action.payload.value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            foundationProfileCanadianApi.endpoints.getGiftsPagedListCAV2.matchFulfilled, (state, action) => {
                state.loadingGifts = false;
                state.giftList.pageNumber = action.payload.pageNumber;
                state.giftList.pageSize = action.payload.pageSize;
                state.giftList.pageCount = action.payload.pageCount;
                state.giftList.totalCount = action.payload.totalCount;
                state.giftList.orderField = action.payload.orderField;
                state.giftList.orderDirection = action.payload.orderDirection;
                state.giftList.items = action.payload.items;
                state.countyList = action.payload.aggrFilters.countiesFilter?.map(county => ({
                    ...county,
                    checked: !!state.countyList.find(o => o.key === county.key)?.checked,
                })) ?? [];
                state.giftList.statesFilter = action.payload.aggrFilters.statesFilter;
            }
        )
        .addMatcher(
            foundationProfileCanadianApi.endpoints.getGiftsPagedListCA.matchPending, (state, action) => {
                state.loadingGifts = true;
            }
        )
        .addMatcher(
            foundationProfileCanadianApi.endpoints.getGiftsPagedListCA.matchRejected, (state, action) => {
                state.loadingGifts = false;
            }
        )
        .addMatcher(
            foundationProfileCanadianApi.endpoints.getGrantOverviewCA.matchFulfilled, (state, action) => {
                state.grantOverviewList.pageNumber = action.payload.pageNumber;
                state.grantOverviewList.pageSize = action.payload.pageSize;
                state.grantOverviewList.pageCount = action.payload.pageCount;
                state.grantOverviewList.totalCount = action.payload.totalCount;
                state.grantOverviewList.items = action.payload.items;
            }
        )
        .addMatcher(
            foundationProfileCanadianApi.endpoints.getDirectorListCA.matchFulfilled, (state, action) => {
                state.directorList.pageNumber = action.payload.pageNumber;
                state.directorList.pageSize = action.payload.pageSize;
                state.directorList.pageCount = action.payload.pageCount;
                state.directorList.totalCount = action.payload.totalCount;
                state.directorList.orderField = action.payload.orderField;
                state.directorList.orderDirection = action.payload.orderDirection;
                state.directorList.items = action.payload.items;
            }
        )
        .addMatcher(
            notesApi.endpoints.getNoteList.matchFulfilled, (state, action) => {
                state.noteList.pageNumber = action.payload.pageNumber;
                state.noteList.pageSize = action.payload.pageSize;
                state.noteList.orderField = action.payload.orderField;
                state.noteList.orderDirection = action.payload.orderDirection;
                state.noteList.pageCount = action.payload.pageCount;
                state.noteList.totalCount = action.payload.totalCount;
                state.noteList.items = action.payload.items;
            }
        )
        .addMatcher(
            foundationProfileCanadianApi.endpoints.getMainProfileCA.matchFulfilled, (state, action) => {
                state.profileName = action.payload.profileName;
                state.lastReportYear = action.payload.lastReportYear;
                state.registerYear = action.payload.registerYear;
                state.fiscalPeriodEnd = action.payload.fiscalPeriodEnd;
                state.stateOrProvince = action.payload.stateOrProvince;
                state.organizationRegion = action.payload.organizationRegion;
                state.city = action.payload.city;
                state.onlineApplication = action.payload.onlineApplication;
                state.website = action.payload.website;
                state.phoneNumber =  formatPhoneNumber(action.payload.phoneNumber);
                state.fullAddress = action.payload.fullAddress;
                state.nteeTitle = action.payload.nteeTitle;
                state.nteeDescription = action.payload.nteeDescription;
                state.contactEmail = action.payload.contactEmail;
                state.donorDesignation = action.payload.donorDesignation;
                
                state.summary.nationalGivingHistory = (action.payload.summary.nationalGivingHistory ?? action.payload.nationalGivingHistory) ?? '';
                state.summary.avgGrantSize = action.payload.summary.avgGrantSize;
                state.summary.totalAsserts = action.payload.summary.totalAsserts;
                state.summary.avgGrantsAnnually = action.payload.summary.avgGrantsAnnually;
                state.summary.grantsState = action.payload.summary.grantsState;

                state.summary.avgAmount = action.payload.summary.avgAmount;
                state.summary.largestAmount = action.payload.summary.largestAmount;
                state.summary.medianAmount = action.payload.summary.medianAmount;
                state.summary.range = action.payload.summary.range;
                state.programData.foundationId = action.payload.programData.foundationId;
                state.taxReturnPdf = action.payload.taxReturnPdf;   
            }
        )
        .addMatcher(
            countryApi.endpoints.getCountryByCodeWithTrigger.matchFulfilled, (state, action) => {

                const countries = [action.payload]; //action.payload value is 1 object (CA)
                state.countryList = countries.map(country => ({
                    id: country.id,
                    value: country.code,
                    label: country.description,
                }))

                state.selectedFilters.country = state.countryList.length > 0 ? [state.countryList[0]] : [];

                state.provinceOrStateList = action.payload.provinceOrStateList.map(provinceOrState => ({
                    id: provinceOrState.id,
                    value: provinceOrState.code,
                    label: provinceOrState.description,
                }));
            }
        )
        .addMatcher(
            countryApi.endpoints.getCityListByProvinceOrStates.matchFulfilled, (state, action) => {
                state.cityList = action.payload.map(city => ({
                    id: city.id,
                    value: city.name,
                    label: city.name,
                    secondaryAction: city.provinceOrStateCode,
                }));

                state.selectedFilters.city = state.cityList.length > 0 
                ? state.selectedFilters.city.filter(city => state.cityList.some(city2 => city2.value === city.value) ) 
                : [];
            }
        )
        .addMatcher(
            sectorCAApi.endpoints.getSectorListCA.matchFulfilled, (state, action) => {
                state.sectorList = action.payload.map(item => ({
                    id: item.id,
                    value: item.sectorName,
                    label: item.sectorName,
                }));
            }
        )
        .addMatcher(
            foundationProfileCanadianApi.endpoints.getChartProvinceListCA.matchFulfilled, (state, action) => {
                state.charts.provinceList = action.payload;
            }
        )
        .addMatcher(
            foundationProfileCanadianApi.endpoints.getChartRangeAmountListCA.matchFulfilled, (state, action) => {
                state.charts.rangeAmount = action.payload;
            }
        )
        .addMatcher(
            foundationProfileCanadianApi.endpoints.getChartRangeSectorCA.matchFulfilled, (state, action) => {
                state.charts.rangeSector = action.payload;
            }
        )
    }
});

export const { 
    init,
    handleChangeRecipientName,
    handleAddNewNote,
    handleEditNote,
    handleClosePopupNote,
    handleChangeStemmingSearch,
    handleClear,
    handleApplyFilter,
    handleClearCityList,
    handleClearGifts,
    handleToggleExpandFilters,
    handleUpdateNoteItem,
    handleChangeFilter,
} = foundationProfileCASliceV2.actions;

export default foundationProfileCASliceV2.reducer;
